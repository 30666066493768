import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as service from "../../services";
import { startLoading, stopLoading } from "../../components/Util";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import HistoryGrid from "../History/HistoryGrid";
import { useAuth } from "../../context/auth";
import ConfirmDialog from "../../components/ConfirmDialog";

function ViewManageMachines(props) {

    const { id } = useParams();
    let { user } = useAuth();
    const navigate = useNavigate();
    const [recipe, setRecipe] = useState({ id });
    const [loading, setLoading] = useState(true);
    const [pathData, setpathData] = useState([]);
    const [isupdate, setIsupdate] = useState(false);
    const [showConfirmDialog, setshowConfirmDialog] = useState(false)
    const [confDialogRecipe, setconfDialogRecipe] = useState({})

    useEffect(() => {
        startLoading();
        let p1 = new Promise((resolve, reject) => {
            if (id !== '0') {
                service.get_machine_details({ id }, res => {
                    if (res.data) {
                        setRecipe({ ...res.data[0] });
                        resolve("");
                    }
                });
            } else resolve("");
        });

        let p2 = new Promise((resolve, reject) => {
            //for paths data
            service.get_locations({ id }, res => {
                setpathData(res.data);
                resolve("");
            });
        });


        Promise.all([p1, p2])
            .then(values => {
                setLoading(false)
                setIsupdate(false)
                stopLoading();
            })
            .catch(error => {
                console.log(error.message);
            });

    }, [id, isupdate]);

    useEffect(() => {
        if (!loading) {
            console.log("loaded...");
        }
    }, [loading]);

    const removeUser = () => {
        startLoading();
        let payload = {
            id: id,
            ...recipe,
            status_id: recipe.status_id === "ACTIVE" ? "DISABLED" : "ACTIVE",
            operation_type: "UPDATE",
            updated_by_name: user.first_name + " " + user.last_name,
            updated_by_id: user.id
        }
        service.upsert_machine({ data: payload }, res => {
            captureAudit(payload)
            setIsupdate(true)
            setshowConfirmDialog(false)
            setconfDialogRecipe({})
        })
    }

    const captureAudit = (data) => {
        data.after_json = { ...data, pathData: pathData };
        data.before_json = { ...data, pathData: pathData }
        data.ref_id = data.id;
        data.menu_id = 'Machine';
        data.action_by_name = user.first_name + " " + user.last_name;
        data.action_by_id = user.id;
        data.action_type = recipe.status_id === "ACTIVE" ? "DISABLED" : "ENABLED"
        data.version_id = data.version;
        data.url_path = '/dashboard/view-machines-history/';

        service.upsert_history({ data }, res => {

        });
    }

    const renderDetail = () => {
        return (
            <>
                <div className="columns">
                    <div className="column mt-4">
                        <div className="columns">
                            <div className="column">
                                <article className="message is-light">
                                    <div className="message-header">Manage Machines
                                        <div className="tags mb-0 is-capitalized">
                                            <span className="tag mb-0 mr-0" style={{ background: "none" }}>Current Status: </span>
                                            <span className={`tag mb-0 mr-0 p-0 is-radiusless ${recipe.status_id === 'ACTIVE' ? 'is-primary' : 'is-danger'}`} style={{ height: "12px", width: "12px" }}></span>

                                            <span className="tag mb-0 pl-1 is-uppercase" style={{ background: "none" }}>{recipe.status_id}</span>
                                            <div className="buttons mb-0">

                                                <button className={`button is-small mb-0 ${recipe.status_id === 'ACTIVE' ? 'is-outlined is-danger' : 'is-success'}`} type="button" style={{ height: "2.2em" }}
                                                    onClick={() => {
                                                        setshowConfirmDialog(true)
                                                        setconfDialogRecipe({
                                                            msg: `Are you sure you want to ${recipe.status_id === 'ACTIVE' ? 'Disable' : 'Enable'} the record?`,
                                                            show: 'status'
                                                        })
                                                    }} >{recipe.status_id === 'ACTIVE' ? "Disable" : "Enable"}</button>

                                                {recipe.status_id === 'ACTIVE' && <button className="button is-outlined is-small mb-0 is-info" style={{ height: "2.2em" }} onClick={() => { navigate("/dashboard/machinedetail/" + recipe.id) }}>
                                                    <span className="icon is-tiny"> <i className="mdi mdi-square-edit-outline line-height-normal"></i> </span>

                                                    <span>Edit</span>
                                                </button>}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="message-body p-0">
                                        <table className="table is-striped is-hoverable is-fullwidth" style={{ border: "1px solid rgb(236, 236, 236)" }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "30%" }}>Machine Address:</td>
                                                    <td><b>{recipe.mac_address}</b></td>
                                                </tr>
                                                {recipe.machine_name &&
                                                    <tr>
                                                        <td style={{ width: "30%" }}>Machine Name:</td>
                                                        <td><b>{recipe.machine_name}</b></td>
                                                    </tr>}

                                                {/* {recipe.created_by_name &&
                                                    <tr>
                                                        <td>Created by</td>
                                                        <td><b>{recipe.created_by_name}</b></td>
                                                    </tr>} */}

                                                {recipe.created_at && <tr>
                                                    <td>Created On</td>
                                                    <td><b>{recipe.created_at}</b></td>
                                                </tr>}

                                                {recipe.updated_by_name && <tr>
                                                    <td>Last Updated by</td>
                                                    <td><b>{recipe.updated_by_name}</b></td>
                                                </tr>}

                                                {recipe.updated_by_name && <tr>
                                                    <td>Last Updated on</td>
                                                    <td><b>{recipe.updated_at}</b></td>
                                                </tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </article>
                            </div>
                        </div>
                        {pathData && pathData.length > 0 &&
                            <div className="columns">
                                <div className="column">
                                    <article className="message is-light">
                                        <div className="message-header">Paths</div>

                                        <div className="message-body p-0">
                                            <table className="table is-striped is-hoverable is-fullwidth" style={{ border: "1px solid #ececec" }}>
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Location Paths</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {pathData.map((item, index) => {
                                                        return <tr key={index}>
                                                            <td style={{ width: '80%' }}>{item.location_path}</td>
                                                            <td style={{ width: '10%', color: item.status_id === 'ACTIVE' ? 'green' : 'red' }}>{item.status_id}</td>
                                                            <td style={{ width: '10%' }}>
                                                                <button className="button is-primary btn-standard" type="button"
                                                                    onClick={() => navigate("/dashboard/view-schedules", { state: { machine_id: item.machine_id, path_id: item.id } })}>
                                                                    View Schedule</button>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </article>
                                </div>
                            </div>}

                        <div className="field is-horizontal">
                            <div className="field-body">
                                <div className="field is-grouped is-grouped-right">
                                    <p className="control">
                                        <button type="button" className="button is-link btn-standard" onClick={() => { navigate(-1) }}>Back</button>
                                    </p>
                                </div>
                            </div>
                        </div>

                        {showConfirmDialog && <ConfirmDialog {...{ ...confDialogRecipe }}
                            hide={() => setshowConfirmDialog(false)}
                            executeCallback={() => removeUser()}
                        />}

                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <div className="container">
                <Tabs style={{ width: "100%" }}
                //</div>defaultIndex={parseInt(localStorage.getItem('tab_index') || 0)} onSelect={index => localStorage.setItem('tab_index', index)}
                >
                    <TabList>
                        <Tab><div className="tab-title">Manage Machines</div></Tab>
                        <Tab><div className="tab-title">History</div></Tab>
                    </TabList>

                    <TabPanel>{renderDetail()}</TabPanel>
                    <TabPanel><HistoryGrid {...{ menu_id: "Machine", reference_id: recipe.id }} /></TabPanel>
                </Tabs>

            </div>
        </>
    )
}

export default ViewManageMachines;
