import ManageMachinesGrid from "./pages/ManageMachines/ManageMachinesGrid";
import ManageMachinesDetails from "./pages/ManageMachines/ManageMachinesDetails";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import Forgot from "./pages/Forgot";
import ResetPassword from "./pages/ResetPassword";
import { AuthProvider } from "./context/auth";
import { ProtectedRoute } from "./pages/ProtectedRoute";
import UsersGrid from "./pages/Users/UsersGrid";
import UserDetails from "./pages/Users/UserDetails";
import "react-datepicker/dist/react-datepicker.css";
import Layout from "./layout/Layout";
import EditUser from "./pages/Users/EditUser";
import TargetsGrid from "./pages/Targets/TargetsGrid";
import CreateTargets from "./pages/Targets/CreateTargets";
import ViewTargets from "./pages/Targets/ViewTargets";
import BackupGrid from "./pages/Backup/BackupGrid";
import CreateBackup from "./pages/Backup/CreateBackup";
import AddSchedules from "./pages/ManageMachines/AddSchedules";
import ViewManageMachines from "./pages/ManageMachines/ViewManageMachines";
import BackupReportGrid from "./pages/Reports/BackupReportGrid";
import DownloadReportGrid from "./pages/Reports/DownloadReportGrid";
import ScheduleReportGrid from "./pages/Reports/ScheduleReportGrid";
import SessionLogReportGrid from "./pages/Reports/SessionLogReportGrid";
import ViewTargetHistory from "./pages/History/ViewTargetHistory";
import ViewUserHistory from "./pages/History/ViewUserHistory";
import ViewMachinesHistory from "./pages/History/ViewMachinesHistory";
import ViewScheduleHistory from "./pages/History/ViewScheduleHistory";
import ViewSchedule from "./pages/ManageMachines/ViewSchedule";
 import TokenValidator from "./components/TokenValidator";

function App() {
  return (
    <BrowserRouter>
      <TokenValidator />
      <AuthProvider>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" exact element={<Navigate to="/dashboard" />} />
            <Route path="dashboard">
              <Route index element={<ProtectedRoute><ManageMachinesGrid /></ProtectedRoute>} />
              <Route path="machinedetail/:id" element={<ProtectedRoute><ManageMachinesDetails /></ProtectedRoute>} />
              <Route path="view-machines/:id" element={<ProtectedRoute><ViewManageMachines /></ProtectedRoute>} />
              <Route path="add-schedules" element={<ProtectedRoute><AddSchedules /></ProtectedRoute>} />
              <Route path="view-schedules" element={<ProtectedRoute><ViewSchedule /></ProtectedRoute>} />
              <Route path="view-machines-history/:id" element={<ProtectedRoute><ViewMachinesHistory /></ProtectedRoute>} />
              <Route path="view-schedules-history/:id" element={<ProtectedRoute><ViewScheduleHistory /></ProtectedRoute>} />
            </Route>
            <Route path="user">
              <Route index element={<ProtectedRoute><UsersGrid /></ProtectedRoute>} />
              <Route path="userdetail/:id" element={<ProtectedRoute><UserDetails /></ProtectedRoute>} />
              <Route path="edit/:id" element={<ProtectedRoute><EditUser /></ProtectedRoute>} />
              <Route path="view-users-history/:id" element={<ProtectedRoute><ViewUserHistory /></ProtectedRoute>} />
            </Route>
            <Route path="target">
              <Route index element={<ProtectedRoute><TargetsGrid /></ProtectedRoute>} />
              <Route path="create-targets/:id" element={<ProtectedRoute><CreateTargets /></ProtectedRoute>} />
              <Route path="view-targets/:id" element={<ProtectedRoute><ViewTargets /></ProtectedRoute>} />
              <Route path="view-targets-history/:id" element={<ProtectedRoute><ViewTargetHistory /></ProtectedRoute>} />
            </Route>
            <Route path="backup">
              <Route index element={<ProtectedRoute><BackupGrid /></ProtectedRoute>} />
              <Route path="create-backup/:id" element={<ProtectedRoute><CreateBackup /></ProtectedRoute>} />
            </Route>
            <Route path="backupreport">
              <Route index element={<ProtectedRoute><BackupReportGrid /></ProtectedRoute>} />
            </Route>
            <Route path="downloadreport">
              <Route index element={<ProtectedRoute><DownloadReportGrid /></ProtectedRoute>} />
            </Route>
            <Route path="schedulereport">
              <Route index element={<ProtectedRoute><ScheduleReportGrid /></ProtectedRoute>} />
            </Route>
            <Route path="sessionlogreport">
              <Route index element={<ProtectedRoute><SessionLogReportGrid /></ProtectedRoute>} />
            </Route>
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
