import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as service from "./../services";

//import { establish_user_types } from "../components/Util";

const authContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('user')) || null);
  const navigate = useNavigate();

  const login = (userDetails) => {
    // userDetails.user_types = establish_user_types(userDetails.auths);
    sessionStorage.setItem('user', JSON.stringify(userDetails));
    setUser(userDetails);
    navigate("/");
  };

  const logout = () => {
    service.logout(() => { })
    setUser(null);
    sessionStorage.clear();
    navigate("/login");
  };

  return (
    <authContext.Provider value={{ user, login, logout }}>
      {children}
    </authContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(authContext);
};