import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
//import "./assets/css/style.css"
import 'material-symbols/sharp.css';
//import "./index.css";
import "./assets/styles/material-icons.css";
import './assets/styles/main.css';
import "./assets/styles/style.css";
import 'react-tabs/style/react-tabs.css';


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <App />
);

reportWebVitals();

