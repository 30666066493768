import { post } from "./service";

export const get_targets = (payload, onDone) => {
    post("/api/targets/grid", payload, (res) => {
        onDone(res);
    });
};
export const upsert_target = (payload, onDone) => {
    post("/api/targets/upsert", payload, (res) => {
        onDone(res);
    });
};
