import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as service from "../../services";
import { startLoading, stopLoading } from "../../components/Util";
import { useNavigate } from "react-router-dom";

function ViewTargetHistory(props) {

    const { id } = useParams();
    const navigate = useNavigate();
    const [loading] = useState(true);
    const [recipe, setRecipe] = useState({ id });


    useEffect(() => {
        startLoading();
        let p1 = new Promise((resolve, reject) => {
            if (id !== '0') {
                service.get_history_data({ id }, res => {
                    if (res.data) {
                        setRecipe({ ...res.data[0] });
                        resolve("");
                    }
                });
            } else resolve("");
        });

        Promise.all([p1])
            .then(values => {
                stopLoading();
            })
            .catch(error => {
                console.log(error.message);
            });

    }, [id]);

    useEffect(() => {
        if (!loading) {
            console.log("loaded...");
        }
    }, [loading]);


    return (
        <>
            <div className="container">
                <div className="column p-0 is-flex-grow-0">
                    <div className="field is-grouped  is-grouped-right is-flex-wrap-nowrap">
                        <div className="control mb-0 is-flex-shrink-0">
                            <div className="tags has-addons is-flex-wrap-nowrap">
                                <span className="tag has-background-success" style={{ height: "1.2rem", borderRadius: "2px" }}>&nbsp;</span>
                                <span className="tag" style={{ background: "none" }}>No Change</span>
                            </div>
                        </div>
                        <div className="control mb-0 is-flex-shrink-0">
                            <div className="tags has-addons is-flex-wrap-nowrap">
                                <span className="tag has-background-warning" style={{ height: "1.2rem", borderRadius: "2px" }}>&nbsp;</span>
                                <span className="tag" style={{ background: "none" }}>Add</span>
                            </div>
                        </div>
                        <div className="control mb-0 is-flex-shrink-0">
                            <div className="tags has-addons is-flex-wrap-nowrap">
                                <span className="tag has-background-danger" style={{ height: "1.2rem", borderRadius: "2px" }}>&nbsp;</span>
                                <span className="tag" style={{ background: "none" }}>Remove</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="columns">
                    <div className="column mt-4">
                        <div className="columns">
                            <div className="column is-half">
                                <article className="message is-light">
                                    <div className="message-header">Targets History
                                        <div>
                                            {recipe.action_type === 'INSERT' && <span className="tag is-success">{recipe.action_type}</span>}
                                            {recipe.action_type !== 'INSERT' && <span className="tag is-warning">{recipe.action_type}</span>}
                                        </div>
                                    </div>
                                    <div className="message-body p-0">
                                        <table className="table is-striped is-hoverable is-fullwidth" style={{ border: "1px solid rgb(236, 236, 236)" }}>
                                            {recipe.after_json &&
                                                <tbody>
                                                    <tr>
                                                        <td><b>Field Name</b></td>
                                                        <td><b>Revison {" "}{recipe.version_id}</b></td>
                                                        <td></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Target Name:</td>
                                                        <td><b>{recipe.after_json.target_name}</b></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Target Type:</td>
                                                        <td><b>{recipe.after_json.target_type_checked}</b></td>
                                                        <td></td>
                                                    </tr>

                                                    {recipe.after_json.target_type_checked === 'FTP' &&
                                                        <>
                                                            <tr>
                                                                <td>IP Address:</td>
                                                                <td><b>{recipe.after_json.ftp_ip_addres}</b></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Port:</td>
                                                                <td><b>{recipe.after_json.ftp_port}</b></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>UserName:</td>
                                                                <td><b>{recipe.after_json.ftp_username}</b></td>
                                                                <td></td>
                                                            </tr>
                                                        </>
                                                    }

                                                    {recipe.after_json.target_type_checked === 'SCP' &&
                                                        <>
                                                            <tr>
                                                                <td>SCP Type:</td>
                                                                <td><b>{recipe.after_json.scp_based_type}</b></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Host Name / IP Address:</td>
                                                                <td><b>{recipe.after_json.scp_host_name}</b></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Port:</td>
                                                                <td><b>{recipe.after_json.scp_port}</b></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>User Name:</td>
                                                                <td><b>{recipe.after_json.scp_user_name}</b></td>
                                                                <td></td>
                                                            </tr>

                                                        </>
                                                    }

                                                    {recipe.after_json.target_type_checked === 'SFTP' &&
                                                        <>
                                                            <tr>
                                                                <td>SFTP Type:</td>
                                                                <td><b>{recipe.after_json.sftp_based_type}</b></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Host Name / IP Address:</td>
                                                                <td><b>{recipe.after_json.sftp_host_name}</b></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Port:</td>
                                                                <td><b>{recipe.after_json.sftp_port}</b></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>User Name:</td>
                                                                <td><b>{recipe.after_json.sftp_user_name}</b></td>
                                                                <td></td>
                                                            </tr>

                                                        </>
                                                    }

                                                    <tr>
                                                        <td>{recipe.action_type === "CREATE" ? 'Created By:' : 'Updated By:'}</td>
                                                        <td><b>{recipe.action_by_name}</b></td>
                                                        <td></td>
                                                    </tr>

                                                    <tr>
                                                        <td>{recipe.action_type === "CREATE" ? 'Created On:' : 'Updated On:'}</td>
                                                        <td><b>{recipe.action_on}</b></td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>}
                                        </table>
                                    </div>
                                </article>
                            </div>
                            <div className="column is-half">
                                <article className="message is-light">
                                    <div className="message-header">
                                        Revision
                                    </div>
                                    <div className="column">
                                        <div className="columns">
                                            <div className="column">
                                                <div className="content">
                                                    <div className="level-item has-text-centered">
                                                        <div>
                                                            <p className="title mt-2" style={{ color: "green" }}>{recipe.version_id}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>

                        <div className="field is-horizontal">
                            <div className="field-body">
                                <div className="field is-grouped is-grouped-right">
                                    <p className="control">
                                        <button type="button" className="button is-link btn-standard" onClick={() => { navigate(-1) }}>Back</button>
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewTargetHistory;
