import React, { useEffect, useState } from "react";
import * as service from "../../services";
import { startLoading, stopLoading } from "../../components/Util";
import { useNavigate } from "react-router-dom";

const HistoryGrid = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true)
  const [store, setStore] = useState([]);

  useEffect(() => {
    startLoading();

    let payload = {
      menu_id: props.menu_id,
      ref_id: props.reference_id
    }
    setLoading(false)
    service.get_audit_data(payload, res => {
      setStore(res.data);
      setLoading(false)
    });
  }, []);


  const renderGrid = () => {
    return <>
      {store && store.length > 0 ?
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="table-container">
                <table className="table is-striped  is-hoverable is-fullwidth">
                  <thead>
                    <tr className="extra-spacing-tr"></tr>
                    <tr>
                      <th scope="col" width="30%"><span className="text-muted">Action Type</span></th>
                      <th scope="col" width="30%"><span className="text-muted">Action By</span></th>
                      <th scope="col" width="30%"><span className="text-muted">Action On</span></th>
                      {props.menu_id !== 'Schedules' && <th scope="col" width="30%"><span className="text-muted">Version</span></th>}
                    </tr>
                  </thead>
                  <tbody>
                    {store.map((data, key) => {
                      return (
                        <tr key={data.id}>
                          <td>
                            <div className="txt-primary">
                              <h6 className="clickable text-darkprime"
                                onClick={() => { navigate(data.url_path + data.id) }}
                              >{data.action_type}
                              </h6>
                            </div>
                          </td>
                          <td>{data.action_by_name}</td>
                          <td>{data.action_on}</td>
                          {props.menu_id !== 'Schedules' && <td>{'V' + data.version_id}</td>}

                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="blank-div">No Data Found.</div>
      }
    </>
  }

  loading ? startLoading() : stopLoading();
  return (
    !loading && <>
      <div className="container">
        <br />
        <div className="columns is-mobile">
          <div className="column">
            <h1 className="title">History</h1>
          </div>
        </div>

        <div className="row px-0">
          {renderGrid()}
        </div>

      </div>
    </>);
};
export default HistoryGrid;
