import React from 'react'
import { NavLink } from 'react-router-dom';
import { useState } from "react";

function Sidebar() {
    const [expand, setDropdown] = useState(false);
    return (
        <div id="sidebar" className="sidebar lims-eln">
            <div id="sidebar-menu">
                <ul className="metismenu list-unstyled">
                    <li className='nav-child-gp'>
                        <NavLink to="user">
                            <div className="nav-link">
                                <span className="icon is-left"><i className="mdi mdi-account"></i></span><span className="sidebar-label">Users</span>
                            </div>
                        </NavLink>
                    </li>
                    <li className='nav-child-gp'>
                        <NavLink to="target">
                            <div className="nav-link">
                                <span className="icon is-left"><i className="mdi mdi-selection-multiple-marker"></i></span><span className="sidebar-label">Targets</span>
                            </div>
                        </NavLink>
                    </li>
                    <li className='nav-child-gp'>
                        <NavLink to="dashboard">
                            <div className="nav-link">
                                <span className="icon is-left"><i className="mdi mdi-app-config"></i></span><span className="sidebar-label">Manage Machines</span>
                            </div>
                        </NavLink>
                    </li>

                    <li className='nav-child-gp'>
                        <NavLink to="backup">
                            <div className="nav-link">
                                <span className="icon is-left"><i className="mdi mdi-account-details-outline"></i></span><span className="sidebar-label">Backup</span>
                            </div>
                        </NavLink>
                    </li>
                    <li className='nav-child-gp'>

                        <div className="nav-link">

                            <span className="icon is-left"><i className="mdi mdi-file-chart-outline"></i>
                            </span><span className="sidebar-label">Reports
                                <i className={expand ? 'mdi mdi-chevron-down pull-left' : 'mdi mdi-chevron-right pull-left'}
                                    onClick={(evt) => { evt.preventDefault(); setDropdown((prev) => !prev) }}
                                ></i>

                            </span>


                        </div>


                    </li>
                   { expand && <li className="sidebar-menu-item nav-child-1">
                        <NavLink to="backupreport">
                            <div className="nav-link">
                                <span className="icon is-left">&nbsp;</span>
                                <span className="sidebar-label">Backup Report</span>
                            </div>
                        </NavLink>
                    </li>}
                    { expand && <li className="sidebar-menu-item nav-child-1">
                        <NavLink to="downloadreport">
                            <div className="nav-link">
                                <span className="icon is-left">&nbsp;</span>
                                <span className="sidebar-label">Download Report</span>
                            </div>
                        </NavLink>
                    </li>}
                    { expand && <li className="sidebar-menu-item nav-child-1">
                        <NavLink to="schedulereport">
                            <div className="nav-link">
                                <span className="icon is-left">&nbsp;</span>
                                <span className="sidebar-label">Schedule Report</span>
                            </div>
                        </NavLink>
                    </li>}
                    { expand && <li className="sidebar-menu-item nav-child-1">
                        <NavLink to="sessionlogreport">
                            <div className="nav-link">
                                <span className="icon is-left">&nbsp;</span>
                                <span className="sidebar-label">Session Log Report</span>
                            </div>
                        </NavLink>
                    </li>}
                </ul>
            </div>
        </div>
    )
}

export default Sidebar