import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as service from "../../services";
import { startLoading, stopLoading } from "../../components/Util";
import AddLocation from "./AddLocation";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../../components/ConfirmDialog";
import { useAuth } from "../../context/auth";

function CustomerDetails(props) {

  let { user } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true);
  const [recipe, setRecipe] = useState({});
  const [showLocationPopup, setshowLocationPopup] = useState(false);
  const [isupdate, setIsupdate] = useState(false);
  const [pathData, setpathData] = useState([])
  const [errors] = useState({})
  const [showConfirmDialog, setshowConfirmDialog] = useState(false)
  const [confDialogRecipe, setconfDialogRecipe] = useState({})


  useEffect(() => {
    startLoading();
    let p1 = new Promise((resolve, reject) => {
      if (id !== '0') {
        service.get_machine_details({ id }, res => {
          if (res.data) {
            setRecipe({ ...res.data[0], before_json: { ...res.data[0] } });
            resolve("");
          }
        });
      } else resolve("");
    });

    let p2 = new Promise((resolve, reject) => {
      //for paths data
      service.get_locations({ id }, res => {
        setpathData(res.data);
        resolve("");
      });
    });


    Promise.all([p1, p2])
      .then(values => {
        setLoading(false)
        setIsupdate(false)
        stopLoading();
      })
      .catch(error => {
        console.log(error.message);
      });

  }, [isupdate, id]);

  useEffect(() => {
    if (!loading) {
      console.log("loaded...");
    }
  }, [loading]);

  const handleChange = (prop, value) => {
    setRecipe(recipe => ({ ...recipe, [prop]: value }));
  };

  const upsert = () => {
    setIsupdate(false)
    let data = JSON.parse(JSON.stringify(recipe));
    data.updated_by_name = user.first_name + " " + user.last_name;
    data.updated_by_id = user.id;
    data.operation_type = "UPDATE";
    data.version = id === '0' ? 0 : recipe.version + 1;
    service.upsert_machine({ data }, res => {
      setIsupdate(true)
      stopLoading();
      captureAudit(data)
      setshowConfirmDialog(true)
      setconfDialogRecipe({
        msg: 'Machines has been ' + (id !== '0' ? 'updated.' : 'created.'),
        is_link: true,
        path: '/dashboard'
      })
    })
  }

  const captureAudit = (data) => {
    data.after_json = { ...data, pathData: pathData };
    data.before_json = id === "0" ? {} : { ...recipe.before_json, pathData: pathData }
    data.ref_id = data.id;
    data.menu_id = 'Machine';
    data.action_by_name = user.first_name + " " + user.last_name;
    data.action_by_id = user.id;
    data.action_type = id === "0" ? "CREATE" : "UPDATE";
    data.version_id = data.version;
    data.url_path = '/dashboard/view-machines-history/';

    service.upsert_history({ data }, res => {

    });
  }

  const capturePathAudit = (data, patharr) => {
    data.after_json = { ...data, pathData: patharr };
    data.before_json = id === "0" ? {} : { ...recipe.before_json, pathData: pathData }
    data.ref_id = data.id;
    data.menu_id = 'Machine';
    data.action_by_name = user.first_name + " " + user.last_name;
    data.action_by_id = user.id;
    data.action_type = id === "0" ? "CREATE" : "UPDATE";
    data.version_id = data.version;
    data.url_path = '/dashboard/view-machines-history/';

    service.upsert_history({ data }, res => {

    });
  }

  const deleteLocations = (item) => {
    let payload = {
      ...item
    }
    service.update_location_status(payload, res => {
      setIsupdate(true)
      stopLoading();
    })
  }
  return (
    <>
      <div className="container">
        <br />
        <h5 className="subtitle is-3 mb-2" style={{ display: "inline", verticalAlign: "middle", paddingRight: "35%" }}>
          Manage Machines
        </h5>

        <hr />

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Machine Address</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input disabled={true} className="input" value={recipe.mac_address || ''} onChange={(e) => handleChange('name', e.target.value)} />
              </div>
              {errors.mac_address && <p className="help is-danger">{errors.mac_address}</p>}
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Machine Name</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input className="input" placeholder="Enter Machine Name" maxLength="100" value={recipe.machine_name || ''} onChange={(e) => handleChange('machine_name', e.target.value)} />
              </div>
              {errors.machine_name && <p className="help is-danger">{errors.machine_name}</p>}
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field is-grouped is-grouped-right">
              <p className="control">
                <button className="button is-primary btn-standard" type="button"
                  onClick={() => upsert()}>Submit</button>
              </p>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <article className="message is-light">
              <div className="message-header">Paths
                <button className="button is-primary btn-standard" type="button"
                  onClick={() => setshowLocationPopup(true)}>Add Location</button>
              </div>
              {console.log("pathData : " + pathData)}
              {pathData && pathData.length > 0 &&
                <div className="message-body p-0">
                  <table className="table is-striped is-hoverable is-fullwidth" style={{ border: "1px solid #ececec" }}>
                    <thead>
                      <tr>
                        <th scope="col">Location Paths</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {pathData.map((item, index) => {
                        return <tr key={index}>
                          <td style={{ width: '90%' }}>{item.location_path}</td>

                          <td style={{ width: '5%' }}>
                            <div class="switch is-small" title={item.status_id === 'ACTIVE' ? 'Disabled Location' : 'Enabled Location'}>
                              <input id={item.id} type="checkbox" name="exampleSwitch" checked={item.status_id === 'ACTIVE'}
                                onChange={(e) => {
                                  pathData[index].status_id = item.status_id === 'ACTIVE' ? 'DISABLED' : 'ACTIVE';
                                  setpathData(pathData);
                                  capturePathAudit(recipe, pathData)
                                  deleteLocations(item);
                                }}
                              />
                              <label for={item.id}></label>
                            </div>
                          </td>

                          <td style={{ width: '5%' }}>
                            {item.status_id === 'ACTIVE' &&
                              <button className="button is-primary btn-standard" type="button"
                                onClick={() => navigate("/dashboard/add-schedules", { state: { machine_id: item.machine_id, path_id: item.id} })}>
                                Add Schedule</button>}
                          </td>
                        </tr>
                      })}
                    </tbody>
                  </table>
                </div>}
            </article>
          </div>
        </div>


        {showLocationPopup && <AddLocation {...{ recipe, seq: pathData && pathData.length > 0 ? pathData.length : 0, pathData }}
          hide={() => setshowLocationPopup(false)}
          cb={(res, pathArr) => {
            if (res) {
              setIsupdate(true)
              capturePathAudit(recipe, pathArr)
            }
            setshowLocationPopup(false)
          }} />
        }

        {showConfirmDialog && <ConfirmDialog {...{ ...confDialogRecipe }}
          hide={() => setshowConfirmDialog(false)}
        />}

        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field is-grouped ">
              <p className="control">
                <button className="button is-primary btn-standard" type="button"
                  onClick={() => navigate(-1)}>Back</button>
              </p>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default CustomerDetails;
