import { post ,ajax_download} from "./service";

export const get_backup = (payload, onDone) => {
    post("/api/backups/grid", payload, (res) => {
        onDone(res);
    });
};
export const get_machines_and_location = (payload, onDone) => {
    post("/api/backups/get_machines_and_location", payload, (res) => {
        onDone(res);
    });
};

export const downloadFile = (id,filename,schedule_id,target_type,backup_id,download_by, onDone) => {
    ajax_download(encodeURI("/api/adbsdownloads/download-file/" + id + "/" + filename+ "/" + schedule_id+ "/" + target_type+ "/" + backup_id+ "/" + download_by), (res) => {
        onDone(res);
    });
};