import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as service from "../../services";
import { startLoading, stopLoading } from "../../components/Util";
import { useNavigate } from "react-router-dom";

function ViewScheduleHistory(props) {

    const { id } = useParams();
    const navigate = useNavigate();
    const [loading] = useState(true);
    const [recipe, setRecipe] = useState({ id });


    useEffect(() => {
        startLoading();
        let p1 = new Promise((resolve, reject) => {
            if (id !== '0') {
                service.get_history_data({ id }, res => {
                    if (res.data) {
                        setRecipe({ ...res.data[0] });
                        resolve("");
                    }
                });
            } else resolve("");
        });

        Promise.all([p1])
            .then(values => {
                stopLoading();
            })
            .catch(error => {
                console.log(error.message);
            });

    }, [id]);

    useEffect(() => {
        if (!loading) {
            console.log("loaded...");
        }
    }, [loading]);

    const getColor = (item, oldPath) => {
        let color = 'orange';
        oldPath && oldPath.map(oItem => {
            if (item.id === oItem.id && item.status_id === oItem.status_id && item.status_id === 'ACTIVE') color = 'green';
            if (item.id === oItem.id && item.status_id === 'DISABLED' && oItem.status_id === 'ACTIVE') color = 'red';
            if (item.id === oItem.id && item.status_id === 'DISABLED') color = 'red';
            return oItem;
        })
        return <div >
            <article className="message is-light" style={{ border: `1px solid ${color}` }} >
                <div className="message-header">Schedule
                    <div className="mx-1 tag is-light" style={{ backgroundColor: `${color}`, color: 'white' }}>{item.status_id === 'DISABLED' ? 'deleted' : item.status_id}</div>
                </div>
                <div className="message-body p-0">
                    <table className="table is-striped is-hoverable is-fullwidth">
                        <tbody>
                            <tr>
                                <td style={{ width: "30%" }}>Schedule Name</td>
                                <td><b>{item.schedules_name}</b></td>
                            </tr>
                            <tr>
                                <td style={{ width: "30%" }}>Backup Type</td>
                                <td><b>{item.backup_type}</b></td>
                            </tr>
                            <tr>
                                <td style={{ width: "30%" }}>Backup Frequency Type</td>
                                <td><b>{item.backup_frequency_type || item.backup_frequency.label}</b></td>
                            </tr>
                            <tr>
                                <td style={{ width: "30%" }}>Backup Frequency Value</td>
                                <td><b>{item.backup_frequency_value}</b></td>
                            </tr>
                            <tr>
                                <td style={{ width: "30%" }}>Schedule Start Date</td>
                                <td><b>{item.scheduled_start_date}</b></td>
                            </tr>
                            <tr>
                                <td style={{ width: "30%" }}>Schedule Start Time</td>
                                <td><b>{item.scheduled_time_value}</b></td>
                            </tr>
                            <tr>
                                <td style={{ width: "30%" }}>Retain Days</td>
                                <td><b>{item.retain_days}</b></td>
                            </tr>

                            <tr>
                                <td style={{ width: "30%" }}>Target</td>
                                <td><b>{item.target_data.map((item, index) => {
                                    return <div key={index}>{item.label}</div>
                                })}</b></td>
                            </tr>

                            {item.updated_by_name && <tr>
                                <td style={{ width: "30%" }}>Added by</td>
                                <td><b>{item.updated_by_name}</b></td>
                            </tr>}
                            {item.updated_by_name && <tr>
                                <td style={{ width: "30%" }}>Added on</td>
                                <td><b>{item.updated_at}</b></td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
            </article>
        </div>;
    }

    return (
        <>
            {recipe.after_json && recipe.after_json.scheduleArr && recipe.after_json.scheduleArr.length > 0 &&
                <div className="container">
                    <div className="column p-0 is-flex-grow-0">
                        <div className="field is-grouped is-grouped-right is-flex-wrap-nowrap">
                            <div className="control mb-0 is-flex-shrink-0">
                                <div className="tags has-addons is-flex-wrap-nowrap">
                                    <span className="tag has-background-success" style={{ height: "1.2rem", borderRadius: "2px" }}>&nbsp;</span>
                                    <span className="tag" style={{ background: "none" }}>No Change</span>
                                </div>
                            </div>
                            <div className="control mb-0 is-flex-shrink-0">
                                <div className="tags has-addons is-flex-wrap-nowrap">
                                    <span className="tag has-background-warning" style={{ height: "1.2rem", borderRadius: "2px" }}>&nbsp;</span>
                                    <span className="tag" style={{ background: "none" }}>Add</span>
                                </div>
                            </div>
                            <div className="control mb-0 is-flex-shrink-0">
                                <div className="tags has-addons is-flex-wrap-nowrap">
                                    <span className="tag has-background-danger" style={{ height: "1.2rem", borderRadius: "2px" }}>&nbsp;</span>
                                    <span className="tag" style={{ background: "none" }}>Remove</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column mt-4">
                            <div className="columns">
                                <div className="column">
                                    <article className="message is-light">
                                        <div className="message-header">Schedules History</div>
                                        {recipe.after_json.scheduleArr.map((item, index) => {
                                            return <div key={index} className='mt-3'>
                                                {getColor(item, recipe.before_json.scheduleArr)}
                                            </div>
                                        })}
                                    </article>
                                </div>

                            </div>

                            <div className="field is-horizontal">
                                <div className="field-body">
                                    <div className="field is-grouped is-grouped-right">
                                        <p className="control">
                                            <button type="button" className="button is-link btn-standard" onClick={() => { navigate(-1) }}>Back</button>
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>}
        </>
    )
}

export default ViewScheduleHistory;
