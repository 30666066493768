import React, { useState, useEffect } from 'react'
import * as service from "../../services";
import { getdate, getUUID, startLoading, stopLoading } from "../../components/Util";
import DatePicker from "react-datepicker";
import moment from 'moment';
import Select from 'react-select';
import { useNavigate, useLocation } from "react-router-dom";
import ConfirmDialog from '../../components/ConfirmDialog';
import { useAuth } from "../../context/auth";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import HistoryGrid from '../History/HistoryGrid';

function AddSchedules(props) {

    const navigate = useNavigate()
    const { state } = useLocation();
    let { user } = useAuth();
    const [showConfirmDialog, setshowConfirmDialog] = useState(false)
    const [confDialogRecipe, setconfDialogRecipe] = useState({})
    const [scheduleArr, setScheduleArr] = useState([
        {
            readonly: false,
            showRemove: false,
            allowRemove: true,
            schedules_name: '',
            backup_type: '',
            backup_frequency: '',
            backup_frequency_value: '',
            scheduled_time_value: '',
            scheduled_start_date: '',
            target_data: '',
            retain_days: '',
            status_id: "ACTIVE",
            errors: {}
        }
    ])
    const [loading, setLoading] = useState(true);
    const [Isupdate, setIsupdate] = useState(false)
    const [lookup, setLookups] = useState({});
    const [schedule_error, setSchedule_error] = useState('')
    const [before_json, setbefore_json] = useState([])

    const backFrequency = [
        { value: 'Hourly', label: 'Hourly' },
        { value: 'Daily', label: 'Daily' },
        { value: 'Weekly', label: 'Weekly' },
    ]

    useEffect(() => {
        startLoading();

        let p1 = new Promise((resolve, reject) => {
            service.get_target_lookups({}, res => {
                setLookups(res);
                resolve("");
            });
        });

        let p2 = new Promise((resolve, reject) => {
            service.get_schedulardetails({ id: state.path_id }, res => {
                if (res.data) {
                    let data = res.data
                    data = data.map(item => {
                        return {
                            ...item,
                            readonly: true,
                            errors: {},
                            backup_frequency: backFrequency ? backFrequency.filter(bfreq => bfreq.value === item.backup_frequency_type)[0] : [],
                            scheduled_start_date: getdate(item.scheduled_start_date)
                        }
                    });
                    setScheduleArr([...data]);
                    setbefore_json([...data])
                    resolve("");
                } else {
                    resolve("");
                }
            });
        });

        Promise.all([p1, p2])
            .then(values => {
                setLoading(false)
                stopLoading();
            })
            .catch(error => {
                console.log(error.message);
            });

    }, [Isupdate]);

    useEffect(() => {
        if (!loading) {
            //console.log("loaded...");
        }
    }, [loading]);

    const addSchedulerHandler = () => {
        let errors = {};
        let obj = {}

        obj.readonly = false;
        obj.showRemove = true;
        obj.allowRemove = true;
        obj.schedules_name = '';
        obj.backup_type = '';
        obj.backup_frequency = '';
        obj.backup_frequency_value = '';
        obj.scheduled_time_value = '';
        obj.scheduled_time = '';
        obj.scheduled_start_date = '';
        obj.target_data = '';
        obj.retain_days = '';
        obj.status_id = "ACTIVE";
        obj.errors = errors;
        setScheduleArr([...scheduleArr, obj])
    }

    const deleteClickHandler = (i) => {
        const schedule = [...scheduleArr];
        schedule.map((item, _index) => {
            if (i === _index) {
                if (item.allowRemove === true) schedule.splice(_index, 1);
            }
            return item;
        })
        setScheduleArr(schedule)
    }

    const handleChange = (_i, prop, value) => {
        scheduleArr.map((item, index) => {
            if (index === _i) scheduleArr[index][prop] = value;
            if (prop === 'scheduled_time') {
                if (index === _i) item.scheduled_time_value = moment(item.scheduled_time).format("HH:mm");
                item.scheduled_time = '';
            }
            return scheduleArr
        });
        setScheduleArr([...scheduleArr])
    }

    const validate = () => {
        setSchedule_error("");
        var serror = true;
        var scheduledata = []
        if (!scheduleArr || scheduleArr.length === 0 || scheduleArr.filter(item => item.status_id === 'ACTIVE').length === 0) {
            setSchedule_error("Please add atleast one schedule.")
            serror = false
        }

        if (scheduleArr && scheduleArr.length > 0) {
            scheduledata = scheduleArr.map((item, index) => {
                item.errors = {};
                if (item.schedules_name === '') {
                    item.errors["schedules_name"] = 'Please enter Schedule Name.'
                    serror = false
                }
                if (item.backup_type === '') {
                    item.errors["backup_type"] = 'Please enter Backup Type.'
                    serror = false
                }
                if (item.backup_frequency === '') {
                    item.errors["backup_frequency"] = 'Please enter Backup Frequency.'
                    serror = false
                }
                if (item.backup_frequency_value === '') {
                    item.errors["backup_frequency_value"] = 'Please enter Backup Frequency Value.'
                    serror = false
                }
                if (item.scheduled_start_date === '') {
                    item.errors["scheduled_start_date"] = 'Please enter Schedule start Date.'
                    serror = false
                }
                if (item.scheduled_time_value === '') {
                    item.errors["scheduled_time_value"] = 'Please enter Schedule Start Time.'
                    serror = false
                }
                if (item.retain_days === '') {
                    item.errors["retain_days"] = 'Please enter Retain Days.'
                    serror = false
                }
                if (item.target_data === '') {
                    item.errors["target_data"] = 'Please enter Select Target Data.'
                    serror = false
                }
                return item;
            });
            setScheduleArr([...scheduledata])
        }

        return serror;
    };

    const submitSchedule = () => {
        if (validate()) {
            scheduleArr.map((item, index) => {
                setIsupdate(false)
                let _v = item.backup_frequency.value === 'Weekly' ? 7 * 24 : item.backup_frequency.value === 'Daily' ? 24 : 1;
                let payload = {
                    ...item,
                    seq: index + 1,
                    id: item.id ? item.id : getUUID(),
                    machine_id: state.machine_id,
                    location_id: state.path_id,
                    backup_frequency_id: item.backup_frequency.value,
                    backup_frequency_type: item.backup_frequency.label,
                    backup_frequency_value: parseInt(item.backup_frequency_value),
                    scheduled_start_date: moment(item.scheduled_start_date).format("DD-MMM-yyyy"),
                    is_full: item.backup_type === "Full" ? true : false,
                    scheduled_in_hours: item.backup_frequency_value * _v,
                    target_data: item.target_data,
                    target_ids: item.target_data.map(item => item.value),
                    updated_by_name: item.id ? item.updated_by_name : user.first_name + " " + user.last_name,
                    updated_by_id: item.id ? item.updated_by_id : user.id,
                    updated_at: item.id ? item.updated_at : moment(new Date()).format("DD-MMM-yyyy hh:mm A"),
                    retain_date: getdate(moment(item.scheduled_start_date).add(item['retain_days'], 'days').format("DD-MMM-yyyy"))
                }

                service.upsert_schedular({ payload }, res => {
                    setIsupdate(true)
                    if (scheduleArr.length === index + 1) {
                        captureAudit()
                        setshowConfirmDialog(true)
                        setconfDialogRecipe({
                            msg: 'Scheduler has been sucessfully submitted. ',
                            goback: true
                        })
                    }
                })
                return item
            })

        }
    }

    const captureAudit = () => {
        let schData = scheduleArr.map(item => {
            return {
                ...item,
                scheduled_start_date: moment(item.scheduled_start_date).format("DD-MMM-yyyy"),
                updated_by_name: item.id ? item.updated_by_name : user.first_name + " " + user.last_name,
                updated_by_id: item.id ? item.updated_by_id : user.id,
                updated_at: item.id ? item.updated_at : moment(new Date()).format("DD-MMM-yyyy hh:mm A")
            }
        });
        console.log("schData",schData);
        let data = {}
        data.after_json = { scheduleArr: schData };
        data.before_json = { scheduleArr: before_json ? before_json : [] }
        data.ref_id = state.path_id;
        data.menu_id = 'Schedules';
        data.action_by_name = user.first_name + " " + user.last_name;
        data.action_by_id = user.id;
        data.action_type = before_json && before_json.length > 0 ? 'UPDATE' : 'CREATE';
        data.url_path = '/dashboard/view-schedules-history/';
        service.upsert_history({ data }, res => {

        });
    }

    const renderDetail = () => {
        return (
            <>
                <div className="container">
                    <div className="field is-horizontal">
                        <div className="field-body">
                            <div className="field">
                                <h5 className="subtitle is-4 pt-1">Add Schedule</h5>
                            </div>
                            <div className="field is-grouped is-justify-content-flex-end">
                                <p className="control">
                                    <button className="button is-primary" onClick={addSchedulerHandler} >Add</button>
                                </p>
                            </div>
                        </div>
                    </div>
                    <hr />

                    <div>
                        {scheduleArr && scheduleArr.length > 0 && scheduleArr.map((item, index) => {
                            return (<>
                                <article className="message is-light" key={index}>
                                    <div className="message-header">Schedule  ({index + 1}) <p className="control">
                                        {item.status_id === 'ACTIVE' && <button className="button is-small is-danger"
                                            onClick={() => {
                                                const schedule = [...scheduleArr];
                                                schedule[index].status_id = 'DISABLED';
                                                setScheduleArr(schedule);
                                                deleteClickHandler(index)
                                            }}
                                        >Remove</button>}
                                        {item.status_id === 'DISABLED' && <span className="mx-1 tag is-light is-danger">Disabled</span>}
                                    </p></div>
                                    <div className="field is-horizontal p-2">
                                        <div className="field-label is-normal">
                                            <label className="label">Schedule Name</label>
                                        </div>
                                        <div className="field-body">
                                            <div className="field">
                                                <div className="control">
                                                    <input
                                                        className="input"
                                                        placeholder='Enter schedules'
                                                        disabled={item.readonly}
                                                        maxLength="100"
                                                        value={item.schedules_name || ''}
                                                        onChange={(e) => handleChange(index, 'schedules_name', e.target.value)} />
                                                </div>
                                                {item.errors.schedules_name && <p className="help is-danger">{item.errors.schedules_name}</p>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="field is-horizontal pt-1 pb-1">
                                        <div className="field-label">
                                            <label className="label">Backup Type</label>
                                        </div>
                                        <div className="field-body">
                                            <div className="field is-narrow">
                                                <label className="checkbox">
                                                    <span className="custom-radio">
                                                        <input type="radio"
                                                            value="Full"
                                                            disabled={item.readonly}
                                                            checked={item.backup_type === "Full"}
                                                            onChange={(e) => handleChange(index, 'backup_type', e.target.value)}
                                                        /><span className="radiomark"></span>
                                                    </span> Full
                                                </label>
                                            </div>
                                            <div className="field is-narrow">
                                                <label className="checkbox">
                                                    <span className="custom-radio">
                                                        <input type="radio"
                                                            value="Incremental"
                                                            disabled={item.readonly}
                                                            checked={item.backup_type === "Incremental"}
                                                            onChange={(e) => handleChange(index, 'backup_type', e.target.value)}
                                                        />
                                                        <span className="radiomark"></span>
                                                    </span> Incremental
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {item.errors.backup_type && <p className="help is-danger">{item.errors.backup_type}</p>}

                                    <div className="field is-horizontal p-2">
                                        <div className="field-label is-normal">
                                            <label className="label">Backup Frequency</label>
                                        </div>
                                        <div className="field-body">
                                            <div className="field">
                                                <div className="control">
                                                    <Select isDisabled={item.readonly} value={item.backup_frequency} options={backFrequency || []} required
                                                        placeholder="Backup Frequency"
                                                        onChange={(e) =>
                                                            handleChange(index, 'backup_frequency', e)
                                                        }
                                                    />
                                                </div>
                                                {item.errors.backup_frequency && <p className="help is-danger">{item.errors.backup_frequency}</p>}
                                            </div>
                                        </div>
                                    </div>


                                    <div className="field is-horizontal p-2">
                                        <div className="field-label is-normal">
                                            <label className="label">Backup Frequency Value</label>
                                        </div>
                                        <div className="field-body">
                                            <div className="field">
                                                <div className="control">
                                                    <input className="input" disabled={item.readonly} placeholder='Backup Frequency Value' maxLength="4" pattern="[0-9]*" value={item.backup_frequency_value || ''} onChange={(e) => handleChange(index, 'backup_frequency_value', (e.target.validity.valid) ? e.target.value : item.backup_frequency_value)} />
                                                </div>
                                                {item.errors.backup_frequency_value && <p className="help is-danger">{item.errors.backup_frequency_value}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field is-horizontal p-2">
                                        <div className="field-label is-normal">
                                            <label className="label">Schedule Start Date</label>
                                        </div>
                                        <div className="field-body">
                                            <div className="field w-half">
                                                <div className="control">
                                                    <DatePicker
                                                        disabled={item.readonly}
                                                        placeholderText="Schedule Start Date" selected={item.scheduled_start_date || ''}
                                                        className="input"
                                                        onChange={(e) => handleChange(index, 'scheduled_start_date', e)}
                                                        //onChange={(date) => this.handleChange('scheduled_start_date', date)}
                                                        dateFormat="dd-MMM-yyyy"
                                                        showMonthDropdown={true}
                                                        selectsStart
                                                        minDate={new Date()}


                                                    />
                                                    {item.errors.scheduled_start_date && <p className="help is-danger">{item.errors.scheduled_start_date}
                                                    </p>}
                                                </div>
                                            </div>

                                            <div className="second-label-field is-normal" style={{ flex_grow: "0" }}>
                                                <label className="label">Time </label>
                                            </div>
                                            <div className="">

                                                <div className="field-body">
                                                    <div className="columns m-0 is-flex-grow-1 is-mobile">
                                                        <div className="column p-0  is-flex">
                                                            <div className="control is-flex-grow-1 pr-1">

                                                                <DatePicker
                                                                    disabled={item.readonly}
                                                                    className="input"
                                                                    onChange={(date) => handleChange(index, 'scheduled_time', date)}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    timeIntervals={5}
                                                                    timeCaption="Time"
                                                                    dateFormat="h:mm"
                                                                    placeholderText="Start Time"
                                                                />
                                                                {item.errors.scheduled_time_value && <p className="help is-danger">{item.errors.scheduled_time_value}
                                                                </p>}
                                                            </div>
                                                            <div className="control is-flex-grow-1 pl-1">
                                                                <input className="input" disabled={true} value={item.scheduled_time_value} ></input>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field is-horizontal p-2">
                                        <div className="field-label is-normal">
                                            <label className="label">Retain Days</label>
                                        </div>
                                        <div className="field-body">
                                            <div className="field">
                                                <div className="control">
                                                    <input
                                                        className="input"
                                                        placeholder='Enter retain Days'
                                                        maxLength="4"
                                                        value={item.retain_days || ''}
                                                        disabled={item.readonly}
                                                        pattern="[0-9]*"
                                                        onChange={(e) => handleChange(index, 'retain_days', (e.target.validity.valid) ? e.target.value : item.retain_days)} />
                                                </div>
                                                {item.errors.retain_days && <p className="help is-danger">{item.errors.retain_days}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field is-horizontal p-2">
                                        <div className="field-label is-normal">
                                            <label className="label">Target</label>
                                        </div>
                                        <div className="field-body">
                                            <div className="field">
                                                <div className="control">
                                                    <Select
                                                        isDisabled={item.readonly}
                                                        value={item.target_data}
                                                        options={lookup.TARGET || []} required
                                                        placeholder="target"
                                                        isMulti
                                                        onChange={(e) =>
                                                            handleChange(index, 'target_data', e)
                                                        }
                                                    />
                                                </div>
                                                {item.errors.target_data && <p className="help is-danger">{item.errors.target_data}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </>)
                        })}
                    </div>

                    {showConfirmDialog && <ConfirmDialog {...{ ...confDialogRecipe }}
                        hide={() => setshowConfirmDialog(false)}
                    // executeCallback={() => }
                    />}
                    {schedule_error && <p className="help is-danger">{schedule_error}</p>}
                    <div className="field is-horizontal">
                        <div className="field-body mt-5 mb-5">
                            <div className="field is-grouped is-grouped-right">
                                <p className="control">
                                    <button className="button is-primary btn-standard" type="button"
                                        onClick={() => navigate(-1)}>Back</button>
                                </p>
                                <p className="control">
                                    <button className="button is-primary btn-standard" type="button"
                                        onClick={submitSchedule}
                                    >Submit</button>
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    }

    return (
        <>
            <div className="container">
                <Tabs style={{ width: "100%" }}
                //</div>defaultIndex={parseInt(localStorage.getItem('tab_index') || 0)} onSelect={index => localStorage.setItem('tab_index', index)}
                >
                    <TabList>
                        <Tab><div className="tab-title">Schedules</div></Tab>
                    </TabList>

                    <TabPanel>{renderDetail()}</TabPanel>
                </Tabs>

            </div>

        </>
    )
}
export default AddSchedules
