import { post } from "./service";

export const upsert_history = (payload, onDone) => {
    post("/api/history/upsert_history", payload, (res) => {
        onDone(res);
    });
};

export const get_audit_data = (payload, onDone) => {
    post("/api/history/grid", payload, (res) => {
        onDone(res);
    });
};
export const get_history_data = (payload, onDone) => {
    post("/api/history/history_data", payload, (res) => {
        onDone(res);
    });
};





