import React, { useEffect, useState } from "react";
import * as service from "../../services";
import { startLoading, stopLoading } from "../../components/Util";
import Select from 'react-select';
import moment from "moment";
import { useNavigate } from "react-router-dom";

const BackupGrid = (props) => {
  const navigate = useNavigate();
  const initialstate = { sort_by: { value: 'location_path', label: "Location" }, record_count: 20, sort_direction: 'desc', is_deleted: 'false' }
  const [loading, setLoading] = useState(true)
  const [store, setStore] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [payload, setPayload] = useState(initialstate);

  const sort_direction_options = [
    { value: 'location', label: "Location" },
    { value: 'machine_name', label: "Machine Name" },
    { value: 'mac_address', label: "Machine Address" }
  ];

  const handleChange = (prop, value) => {
    const _payload = JSON.parse(JSON.stringify(payload));
    _payload[prop] = value;
    setPayload(_payload);
  };

  const handleLoadMore = () => {
    setShowMore(false);
    let obj = store[store.length - 1];
    sessionStorage.setItem('user_filter', JSON.stringify(payload));
    const _payload = JSON.parse(JSON.stringify(payload));
    _payload.sort_by = _payload.sort_by.value;
    _payload.last_key = obj[_payload.sort_by + '_srt'];   
    service.get_machines_and_location(_payload, res => {
      setStore([...store, ...res.data]);
      if (res.data.length > 19) setShowMore(true)
    });
  };

  const sortClickHandler = val => {
    setPayload({ ...payload, sort_by: val, last_key: null });
  }

  const sort_directionClickHandler = () => {
    setPayload({ ...payload, sort_direction: payload.sort_direction === "asc" ? "desc" : "asc", last_key: null });
  }

  const handleReset = () => {
    setPayload(initialstate);
  }

  useEffect(() => {
    startLoading();
    sessionStorage.setItem('user_filter', JSON.stringify(payload));
    const _payload = JSON.parse(JSON.stringify(payload));
    if (_payload.created_from_date && _payload.created_to_date) {
      _payload.created_from_date = moment(payload.created_from_date).format("DD-MMM-yyyy") + ' 00:00:01';
      _payload.created_to_date = moment(payload.created_to_date).format("DD-MMM-yyyy") + ' 23:59:59';
    }
    _payload.sort_by = _payload.sort_by.value; 
    if (_payload.search_string) {     
      _payload.search_string = _payload.search_string.replace(/:/g, '');
    } 
    setLoading(false)
    service.get_machines_and_location(_payload, res => {
      setStore(res.data);      
      if (res.data.length > 19) setShowMore(true);
      setLoading(false)
    });
  }, [payload]);


  const renderGrid = () => {
    return <>
      {store && store.length > 0 ?
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="table-container">
                <table className="table is-striped  is-hoverable is-fullwidth">
                  <thead>
                    <tr className="extra-spacing-tr"></tr>
                    <tr>
                      <th scope="col" width="30%"><span className="text-muted">Location</span></th>
                      <th scope="col"><span className="text-muted">Machine Name</span></th>
                      <th scope="col"><span className="text-muted">Machine Address</span></th>
                    </tr>
                  </thead>
                  <tbody>
                    {store.map((data, key) => {
                      return (
                        <tr key={data.id}>
                          <td>
                            <div className="txt-primary">
                              <h6 className="clickable text-darkprime" onClick={() => {
                                // navigate("/backup/create-backup/" + data.id)
                                navigate("/backup/create-backup/" + data.id, { state: { data } })
                              }} >{data.location_path}
                              </h6>
                            </div>
                          </td>
                          <td>{data.machine_name}</td>
                          <td>{data.mac_address}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="blank-div">No Data Found.</div>
      }
      <div style={{ "textAlign": "center" }}> {store && store.length > 19 && showMore ? <button className="btn btn-outline-primary" onClick={() => handleLoadMore()} id="loadMore" > Show More ... </button> : undefined}</div>
    </>
  }

  loading ? startLoading() : stopLoading();
  return (
    !loading && <>
      <div className="container">
        <br />
        <div className="columns is-mobile">
          <div className="column">
            <h1 className="title">Backup</h1>
          </div>

        </div>
        <div className="columns">
          <div className="column">
            <div className="columns is-desktop" style={{ paddingTop: "10px", marginBottom: "0px" }}>
              <div className="column is-half-desktop">
                <label htmlFor="Search" className="form-label mb-0"><small>SEARCH</small></label>
                <div className="control has-icons-left">
                  <input placeholder='Search' type="search" className="input" maxLength="100"
                    value={payload.search_string || ''}
                    onChange={(e) => { handleChange('search_string', e.target.value) }} />
                  <span className="icon is-small is-left"><i className="mdi mdi-magnify"></i></span>
                </div>
              </div>
              <div className="column">
                <div className="columns is-mobile is-vcentered">
                  <div className="column">
                    <label className="form-label mb-0"><small>SORT BY</small></label>
                    <div className="control">
                      <Select
                        value={payload.sort_by || sort_direction_options[0]}
                        options={sort_direction_options || []}
                        required
                        placeholder="Sort By"
                        onChange={e => {
                          sortClickHandler(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="column is-flex-grow-0">
                    <label className="form-label mb-0">&nbsp;</label>
                    <button className="button" style={{ minWidth: "90px" }} onClick={sort_directionClickHandler}>
                      <span className="icon is-small">
                        <i className={payload.sort_direction === 'asc' ? "mdi mdi-sort-reverse-variant" : "mdi mdi-sort-variant"}></i>
                      </span>
                      <span>{payload.sort_direction === 'asc' ? "A-Z" : "Z-A"}</span>
                    </button>
                  </div>
                  <div className="column is-flex-grow-0">
                    <label className="form-label mb-0">&nbsp;</label>
                    <button type="button" className="button is-link" onClick={() => handleReset()}>Reset</button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="row px-0">
          {renderGrid()}
        </div>


      </div>
    </>);
};
export default BackupGrid;
