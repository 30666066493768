import axios from "axios";
import JsFileDownloader from 'js-file-downloader';

const capturelastActivity = () => {
    sessionStorage.setItem('last_activity', Date.now());
};

const get = (url, onDone) => {
    capturelastActivity();
    axios({
        method: "get",
        url: url,
        headers: {
            Authorization: "Bearer " + JSON.parse(sessionStorage.getItem('token')),
            'Access-Control-Allow-Origin': '*'
        }
    }).then(res => {
        if (res.data.msg && res.data.msg === 'Invalid token: ExpiredSignature') {
            sessionStorage.setItem('isTokenExpired', true);
        }
        if (res.data && res.data.code && res.data.code === 400) {
            sessionStorage.setItem('isTokenExpired', true);
            sessionStorage.removeItem("user");
            window.location.href = "/login";
        } else if (res.data && res.data.code && res.data.code === 500) {
            console.log("ERROR: " + res.data.msg)
        } else {
            onDone(res.data);
        }
    }).catch(error => {
        if (error.response) {
            if (error.response.status === 401) {
                sessionStorage.removeItem("user");
                window.location.href = "/login";
            }
        }
    });
};

const archive = (url, onDone) => {
    axios({
        method: "delete",
        url: url,
        headers: {
            Authorization: "Bearer " + JSON.parse(sessionStorage.getItem('token')),
            'Access-Control-Allow-Origin': '*'
        }
    }).then(res => {
        if (res.data.msg && res.data.msg === 'Invalid token: ExpiredSignature') {
            sessionStorage.setItem('isTokenExpired', true);
        }
        if (res.data && res.data.code && res.data.code === 400) {
            sessionStorage.removeItem("user");
            window.location.href = "/login";
        } else if (res.data && res.data.code && res.data.code === 500) {
            console.log("ERROR: " + res.data.msg)
        } else {
            onDone(res.data);
        }
    }).catch(error => {
        if (error.response) {
            if (error.response.status === 401) {
                sessionStorage.removeItem("user");
                window.location.href = "/login";
            }
        }
    });
};

const post = (url, payload, onDone) => {
    capturelastActivity();
    axios({
        method: "post",
        url: url,
        data: payload,
        headers: {
            Authorization: "Bearer " + JSON.parse(sessionStorage.getItem('token')),
            'Access-Control-Allow-Origin': '*'
        }
    }).then(res => {
        if (res.data && res.data.code && res.data.code === 400) {
            localStorage.setItem('isTokenExpired', true);
            sessionStorage.removeItem("user");
            window.location.href = "/login";
        } else if (res.data && res.data.code && res.data.code === 500) {
            console.log("ERROR: " + res.data.msg)
        } if (res.data.msg && res.data.msg === 'Invalid token: ExpiredSignature') {
            sessionStorage.setItem('isTokenExpired', true);
        } else {
            onDone(res.data);
        }
    }).catch(error => {
        if (error.response) {
            if (error.response.status === 409) {
                onDone({ status: 'ERROR', data: error.response.data.detail });
            }
            if (error.response.status === 401) {
                sessionStorage.removeItem("user");
                window.location.href = "/login";
            }
        }
    });
};

const loginpost = (url, payload, onDone) => {
    axios({
        method: "post",
        url: url,
        data: payload
    }).then(res => {
        if (res.data && res.data.code && res.data.code === 400) {
            sessionStorage.removeItem("user");
            window.location.href = "/login";
        } else if (res.data && res.data.code && res.data.code === 500) {
            console.log("ERROR: " + res.data.msg)
        } else {
            onDone(res.data);
        }
    }).catch(error => {
        if (error.response) {
            if (error.response.status === 409) {
                onDone({ status: 'ERROR', data: error.response.data.detail });
            }
            if (error.response.status === 401) {
                sessionStorage.removeItem("user");
                window.location.href = "/login";
            }
        }
    });
};

const patch = (url, payload, onDone) => {
    axios({
        method: "patch",
        url: url,
        data: payload,
        headers: {
            Authorization: "Bearer " + JSON.parse(sessionStorage.getItem('token')),
            'Access-Control-Allow-Origin': '*'
        }
    }).then(res => {
        if (res.data && res.data.code && res.data.code === 400) {
            sessionStorage.removeItem("user");
            window.location.href = "/login";
        } else if (res.data && res.data.code && res.data.code === 500) {
            console.log("ERROR: " + res.data.msg)
        } else {
            onDone(res.data);
        }
    }).catch(error => {
        if (error.response) {
            if (error.response.status === 409) {
                onDone({ status: 'ERROR', data: error.response.data.detail });
            }
            if (error.response.status === 401) {
                sessionStorage.removeItem("user");
                window.location.href = "/login";
            }
        }
    });
};

const download = (url, payload, onDone) => {
    axios({
        method: "post",
        url: url,
        data: payload,
        responseType: 'arraybuffer',
        headers: {
            Authorization: "Bearer " + JSON.parse(sessionStorage.getItem('token')),
            'Access-Control-Allow-Origin': '*'
        }
    }).then(res => {
        if (res.data && res.data.code && res.data.code === 400) {
            sessionStorage.removeItem("user");
            window.location.href = "/login";
        } else if (res.data && res.data.code && res.data.code === 500) {
            console.log("ERROR: " + res.data.msg)
        } else {
            onDone(res.data);
        }
    }).catch(error => {
        if (error.response.status === 401) {
            sessionStorage.removeItem("user");
            window.location.href = "/login";
        } else console.log(error);
    });
};

const ajax_download = (url, onDone) => {
    // capturelastActivity();
    // startLoading();
    new JsFileDownloader({
        url: url,
        headers: [
            { name: 'Authorization', value: "Bearer " + JSON.parse(localStorage.getItem('token')) },
            { name: 'Access-Control-Allow-Origin', value: '*' }
        ]
    })
        .then(function () {
            //stopLoading();
            onDone()
        })
        .catch(function (error) {
            // stopLoading();
            onDone()
        });
}
export { get, post, patch, download, archive, ajax_download, loginpost };
