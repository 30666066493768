//import logo from "../assets/images/chc-logo.svg";
import React, { useState, useRef } from 'react';
import { useAuth } from "../context/auth";
import * as service from "../services";
import { useNavigate } from "react-router-dom";

function Login(props) {
    const navigate = useNavigate();
    const usernameElement = useRef(null);
    const passwordElement = useRef(null);

    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    //const [passwordShown, setPasswordShown] = useState(false);
    const [errors, setErrors] = useState({})
    const { login, logout } = useAuth();

    React.useEffect(() => {
        setUserName(usernameElement.current.value || '');
        setPassword(passwordElement.current.value || '');
    }, [usernameElement, passwordElement])

    const validate = () => {
        setErrors({});
        let isValid = true;
        if (!username || username.length <= 0) {
            setErrors({ loginFailure: 'Please enter user name.' });
            isValid = false;
        }

        if (username && (!password || password.length <= 0)) {
            setErrors({ loginFailure: 'Please enter password.' });
            isValid = false;
        }

        return isValid;
    };
    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            handleLoginSubmit();
        }
    }

    const handleLoginSubmit = () => {
        if (validate()) {
            service.login({ username, password }, (res) => {
                // console.log(res.status);
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        setErrors({ loginFailure: res.msg });
                    } else {
                        sessionStorage.setItem('token', JSON.stringify(res.data));
                        service.introspect(user_res => {
                            let user = user_res.data;
                            // console.log("user Data Service ", user)
                            if (user.password_reset === true) {
                                // console.log("navigate to reset page")
                                navigate("/reset-password", { state: username });
                            } else {
                                // service.authorizations(auth_res => {
                                //     if (user) user.auths = auth_res.data || [];
                                //     if (user.auths && user.auths.length > 0) {
                                //         login(user);
                                //     } else {
                                //         logout();
                                //         setErrors({ loginFailure: 'Unauthorised Access.' });
                                //     }

                                // });
                                login(user);
                            }
                        });
                    }
                }
            });
        }
    }


    return (

        <div className="login lims-eln-login">
            <div className="lm-login-inner">
                <div className="form-signin">
                    <div className="logos lims-eln-logo"></div>
                    <p className="help is-danger"><small>{errors.loginFailure}</small></p>
                    <div className="field">
                        <label className="label">User Name</label>
                        <div className="control has-icons-left">
                            <input ref={usernameElement} autoComplete="username" type="email" className="input" id="floatingInput" placeholder="name@example.com"
                                value={username || ''}
                                onChange={(e) => setUserName(e.target.value)}
                                required
                                autoFocus
                            />
                            <span className="icon is-small is-left">
                                <i className="mdi mdi-account"></i>
                            </span>
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">Password</label>
                        <div className="control has-icons-left">
                            <input ref={passwordElement} autoComplete="current-password" type="password" className="input" id="floatingPassword" placeholder="Password"
                                value={password || ''}
                                onKeyDown={handleKeyDown}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <span className="icon is-small is-left">
                                <i className="mdi mdi-key"></i>
                            </span>
                        </div>

                    </div>


                    <button type="button" className="button is-info btn-standard" style={{ marginTop: "5px", marginBottom: "5px", float: "right" }} onClick={() => { handleLoginSubmit() }} autoFocus={true}><small>Sign in</small></button>
                    <div className="field">
                        <div className="control">
                            <button className="button is-ghost is-shadowless pr-0 has-text-grey-light" style={{ marginTop: "5px", marginBottom: "5px", float: 'right', fontSize: "small" }} onClick={() => navigate('/forgot')}>Forgot Password </button>
                        </div>
                    </div>


                </div>
            </div>
        </div>

    );
}

export default Login;
