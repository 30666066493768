import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as service from "../../services";
import { startLoading, stopLoading, randomString } from "../../components/Util";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import HistoryGrid from "../History/HistoryGrid";
import { useAuth } from "../../context/auth";
import ConfirmDialog from "../../components/ConfirmDialog";

function UserDetails(props) {


  const { id } = useParams();
  let { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [recipe, setRecipe] = useState({ id });
  const [userId] = useState(id);
  const [passMessage, setPassMessage] = useState("Once password is send you will receive email with temporary password.");
  const [isUpdate, setIsUpdate] = useState(false);
  const [showConfirmDialog, setshowConfirmDialog] = useState(false)
  const [confDialogRecipe, setconfDialogRecipe] = useState({})


  useEffect(() => {
    startLoading();
    let p1 = new Promise((resolve, reject) => {
      if (id !== '0') {
        service.get_users({ id }, res => {
          if (res.data) {
            console.log("res.data[0]", res.data[0]);
            setRecipe({ ...res.data[0] });
            resolve("");
          }
        });
      } else resolve("");
    });

    Promise.all([p1])
      .then(values => {
        setLoading(false)
        setIsUpdate(false)
        stopLoading();
      })
      .catch(error => {
        console.log(error.message);
      });
  }, [id, isUpdate]);

  useEffect(() => {
    if (!loading) {
      console.log("loaded...");
    }
  }, [loading]);

  const sendPassword = () => {

    startLoading();
    let payload = JSON.parse(JSON.stringify(recipe));
    payload.operation_type = "RESET_PASSWORD";
    payload.user_id = userId;
    payload.password = randomString(15);
    service.send_password_by_id(payload, res => {
      stopLoading();
      if (res.status === 'SUCCESS') {
        setPassMessage("Password Sent.")
      }
    })

  }

  const removeUser = () => {
    startLoading();
    let payload = {
      id: id,
      ...recipe,
      status_id: recipe.status_id === "ACTIVE" ? "DISABLED" : "ACTIVE",
      operation_type: "UPDATE"
    }
    service.upsert_user(payload, res => {
      captureAudit(payload)
      setIsUpdate(true)
      setshowConfirmDialog(false)
      setconfDialogRecipe({})
    })
  }

  const captureAudit = (data) => {
    data.after_json = { ...data };
    data.before_json = { ...data }
    data.ref_id = data.id;
    data.menu_id = 'User';
    data.action_by_name = user.first_name + " " + user.last_name;
    data.action_by_id = user.id;
    data.action_type = recipe.status_id === "ACTIVE" ? "DISABLED" : "ENABLED"
    data.url_path = '/user/view-users-history/';
    data.version_id = data.version
    service.upsert_history({ data }, res => {

    });
  }

  const renderDetail = () => {
    return (
      <>
        <div className="container">
          <div className="columns">
            <div className="column mt-4">
              <div className="columns">
                <div className="column">
                  <article className="message is-light">
                    <div className="message-header">Users
                      <div className="tags mb-0 is-capitalized">
                        <span className="tag mb-0 mr-0" style={{ background: "none" }}>Current Status: </span>
                        <span className={`tag mb-0 mr-0 p-0 is-radiusless ${recipe.status_id === 'ACTIVE' ? 'is-primary' : 'is-danger'}`} style={{ height: "12px", width: "12px" }}></span>

                        <span className="tag mb-0 pl-1 is-uppercase" style={{ background: "none" }}>{recipe.status_id}</span>
                        <div className="buttons mb-0">

                          <button className={`button is-small mb-0 ${recipe.status_id === 'ACTIVE' ? 'is-outlined is-danger' : 'is-success'}`} type="button" style={{ height: "2.2em" }}
                            onClick={() => {
                              setshowConfirmDialog(true)
                              setconfDialogRecipe({
                                msg: `Are you sure you want to ${recipe.status_id === 'ACTIVE' ? 'Disable' : 'Enable'} the record?`,
                                show: 'status'
                              })
                            }}
                          >{recipe.status_id === 'ACTIVE' ? "Disable" : "Enable"}</button>

                          {recipe.status_id === 'ACTIVE' && <button className="button is-outlined is-small mb-0 is-info" style={{ height: "2.2em" }}>
                            <span className="icon is-tiny"> <i className="mdi mdi-square-edit-outline line-height-normal"></i> </span>

                            <span onClick={() => { navigate("/user/edit/" + recipe.id) }}>Edit</span>
                          </button>}

                        </div>
                      </div>
                    </div>
                    <div className="message-body p-0">
                      <table className="table is-striped is-hoverable is-fullwidth" style={{ border: "1px solid rgb(236, 236, 236)" }}>
                        <tbody>
                          <tr>
                            <td style={{ width: "30%" }}>First Name:</td>
                            <td><b>{recipe.first_name}</b></td>
                          </tr>
                          <tr>
                            <td style={{ width: "30%" }}>Last Name:</td>
                            <td><b>{recipe.last_name}</b></td>
                          </tr>
                          <tr>
                            <td style={{ width: "30%" }}>Email Id:</td>
                            <td><b>{recipe.email}</b></td>
                          </tr>
                          <tr>
                            <td style={{ width: "30%" }}>Phone Number:</td>
                            <td><b>{recipe.phone_number}</b></td>
                          </tr>
                          <tr>
                            <td>Created by</td>
                            <td><b>{recipe.created_by_name}</b></td>
                          </tr>
                          <tr>
                            <td>Created On</td>
                            <td><b>{recipe.created_at}</b></td>
                          </tr>
                          {recipe.updated_by_name && <tr>
                            <td>Last Updated by</td>
                            <td><b>{recipe.updated_by_name}</b></td>
                          </tr>}
                          {recipe.updated_by_name && <tr>
                            <td>Last Updated on</td>
                            <td><b>{recipe.updated_at}</b></td>
                          </tr>}
                        </tbody>
                      </table>
                    </div>
                  </article>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <article className="message is-info">
                    <div className="message-header">Password Reset </div>
                    <div className="message-body">
                      <div className="content">
                        <p>{passMessage} </p>
                        <div className="control">
                          <button className="button is-info btn-standard" type="button" disabled={userId === '0'} onClick={e => { sendPassword() }}>Send Password</button>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>

              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field is-grouped is-grouped-right">
                    <p className="control">
                      <button type="button" className="button is-link btn-standard" onClick={() => { navigate(-1) }}>Back</button>
                    </p>
                  </div>
                </div>
              </div>

              {showConfirmDialog && <ConfirmDialog {...{ ...confDialogRecipe }}
                hide={() => setshowConfirmDialog(false)}
                executeCallback={() => removeUser()}
              />}

            </div>
          </div>
        </div >
      </>
    )
  }

  return (
    <>
      <div className="container">
        <Tabs style={{ width: "100%" }} >
          <TabList>
            <Tab><div className="tab-title">Users</div></Tab>
            <Tab><div className="tab-title">History</div></Tab>
          </TabList>
          <TabPanel>{renderDetail()}</TabPanel>
          <TabPanel><HistoryGrid {...{ menu_id: "User", reference_id: recipe.id }} /></TabPanel>
        </Tabs>
      </div>
    </>
  )
}

export default UserDetails;
