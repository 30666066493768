import bigDecimal from "js-big-decimal";
import { v4 as uuidv4 } from 'uuid';

export const startLoading = () => {
  const isTokenExpired = localStorage.getItem("isTokenExpired") || false;
  if (!isTokenExpired)
    if (document.getElementById("loader")) document.getElementById("loader").style.display = "block";
};

export const stopLoading = () => {
  document.getElementById("loader").style.display = "none";
};

export const validateEmail = (email) => {
  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
  return pattern.test(email)
}

export const isEmpty = (obj) => {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop))
      return false;
  }
  return true;
}

export const getUUID = () => uuidv4()

export const randomString = (length) => {
  var result = [];
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%&*';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  return result.join('');
}

export const formatCurrency = (price, dec) => {
  if (!dec) dec = 0;
  const mult = Math.pow(10, dec);
  const num = Math.round(price * mult) / mult;

  //console.log(num, bigDecimal.getPrettyValue(bigDecimal.round(num, 0)))
  try {
    return bigDecimal.getPrettyValue(bigDecimal.round(num, dec));
  } catch (e) {
    console.warn(e);
  }
};
export const getDistinctValArray = (data, key) => {
  return [...new Map(data.map(item => [item[key], item])).values()];
}

export const getStatusClass = (status) => {
  let _className = undefined;
  if (status === "Approved") _className = "badge bg-success";
  if (status === "Request Created") _className = "badge bg-info";
  if (status === "Pending Approval") _className = "badge bg-warning";
  if (status === "Rejected") _className = "badge bg-danger";
  if (status === "Cancelled") _className = "badge bg-secondary";
  if (status === "Exception Found") _className = "badge bg-secondary";
  return _className;
}

export const arrayToMap = (data, key) => {
  let map = {};
  data.map(item => {
    let _key = item[key];
    if (_key in map)
      map[_key].push(item);
    else
      map[_key] = [item];
    return undefined;
  });
  return map;
}

export const establish_user_types = (auths) => {
  let user_types = {
    is_admin: auths.filter(item => item.user_role_id === 'ADMIN').length > 0,
    is_qc: auths.filter(item => item.user_role_id === 'QC').length > 0,
    is_sper_user: auths.filter(item => item.user_role_id === 'SUPER.USER').length > 0
  };
  return user_types;
}

export const getdate = (_str) => {
  let temp = undefined;
  let _date = undefined;
  let date_str = _str.trim();
  temp = date_str.split("-");
  _date = temp[2] + "-" + getMonthNumber(temp[1]) + "-" + temp[0];
  _date = new Date(_date);
  return _date;
};

export const getMonthNumber = (monthName) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  //const monthNames = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
  let ret = 0;
  monthNames.map((item, index) => {
    if (item === monthName) ret = index + 1;
    return item;
  });
  return ret;
};

