import React, { useState } from 'react';
import * as service from "../../services";

function AddLocation(props) {
    const [location_path, setLocation_path] = useState('');
    const [errors, setErrors] = useState({});

    const cancelClickHandler = () => {
        props.cb();
    }

    const validate = () => {
        setErrors({});
        let isValid = true;
        let validation = [
            { field: 'location_path', msg: 'Please enter Path Location.' }
        ];

        validation.map(item => {
            if (!location_path || location_path <= 0) {
                setErrors(errors => ({ ...errors, location_path: item.msg }));
                isValid = false;
            }
            return item;
        });
        return isValid;
    };

    const saveData = () => {
        let temp = []
        if (validate()) {
            let payload = {
                location_path,
                machine_id: props.recipe.id,
                machine_name: props.recipe.machine_name,
                mac_address: props.recipe.mac_address,
                seq: props.seq + 1,
                status_id: 'ACTIVE'
            }
            temp.push(payload)
            service.set_locations({ payload }, res => {
                let data = undefined
                if (props.pathData) {
                    data = [...props.pathData, ...temp]
                } else data = [...temp]
                
                props.cb(res, data)
            })
        }
    }

    return (
        <div className='popup'>
            <div className='modal' style={{ display: "flex" }}>
                <div className='modal-card' style={{ width: '800px' }}>
                    <header className="modal-card-head">
                        <p className="modal-card-title">Add Location</p>
                    </header>
                    <section className="modal-card-body">
                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">Location</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control">
                                        <input
                                            className="input"
                                            placeholder='Enter Path'
                                            maxLength="100"
                                            value={location_path || ''}
                                            onChange={(e) => setLocation_path(e.target.value)}

                                        />
                                    </div>
                                    {errors.location_path && <p className="help is-danger">{errors.location_path}</p>}
                                </div>
                            </div>
                        </div>

                    </section>
                    <footer className="modal-card-foot is-justify-content-right">
                        <button className="button is-link btn-standard" style={{ minWidth: "100px", marginRight: '5px' }} type="button" onClick={() => cancelClickHandler()}>Cancel</button>
                        <button className="button is-primary btn-standard" style={{ minWidth: "100px" }} type="button" onClick={() => saveData()}>Save</button>
                    </footer>
                </div>
            </div>
        </div>
    )
}


export default AddLocation;