import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as service from "../../services";
import { startLoading, stopLoading } from "../../components/Util";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import HistoryGrid from "../History/HistoryGrid";
import { useAuth } from "../../context/auth";
import ConfirmDialog from "../../components/ConfirmDialog";

function ViewTargets(props) {

  const { id } = useParams();
  let { user } = useAuth();
  const navigate = useNavigate();
  const [loading] = useState(true);
  const [recipe, setRecipe] = useState({ id });
  const [isUpdate, setIsUpdate] = useState(false);
  const [showConfirmDialog, setshowConfirmDialog] = useState(false)
  const [confDialogRecipe, setconfDialogRecipe] = useState({})

  useEffect(() => {
    startLoading();
    let p1 = new Promise((resolve, reject) => {
      if (id !== '0') {
        service.get_targets({ id }, res => {
          if (res.data) {
            setRecipe({ ...res.data[0] });
            resolve("");
          }
        });
      } else resolve("");
    });

    Promise.all([p1])
      .then(values => {
        setIsUpdate(false)
        stopLoading();
      })
      .catch(error => {
        console.log(error.message);
      });

  }, [id, isUpdate]);

  useEffect(() => {
    if (!loading) {
      console.log("loaded...");
    }
  }, [loading]);

  const removeUser = () => {
    startLoading();
    let payload = {
      id: id,
      ...recipe,
      status_id: recipe.status_id === "ACTIVE" ? "DISABLED" : "ACTIVE",
      operation_type: "UPDATE",
      updated_by_name: user.first_name + " " + user.last_name,
      updated_by_id: user.id,
      updated_at: new Date()
    }
    service.upsert_target({ data: payload }, res => {
      captureAudit(payload)
      setIsUpdate(true)
      setshowConfirmDialog(false)
      setconfDialogRecipe({})
    })
  }

  const captureAudit = (data) => {
    data.after_json = { ...data };
    data.before_json = { ...data }
    data.ref_id = data.id;
    data.menu_id = 'Target';
    data.action_by_name = user.first_name + " " + user.last_name;
    data.action_by_id = user.id;
    data.action_type = recipe.status_id === "ACTIVE" ? "DISABLED" : "ENABLED"
    data.version_id = 0;
    data.url_path = '/target/view-targets-history/';

    service.upsert_history({ data }, res => {

    });
  }

  const renderDetail = () => {
    return (
      <>
        <div className="columns">
          <div className="column mt-4">
            <div className="columns">
              <div className="column">
                <article className="message is-light">
                  <div className="message-header">Targets
                    <div className="tags mb-0 is-capitalized">
                      <span className="tag mb-0 mr-0" style={{ background: "none" }}>Current Status: </span>
                      <span className={`tag mb-0 mr-0 p-0 is-radiusless ${recipe.status_id === 'ACTIVE' ? 'is-primary' : 'is-danger'}`} style={{ height: "12px", width: "12px" }}></span>

                      <span className="tag mb-0 pl-1 is-uppercase" style={{ background: "none" }}>{recipe.status_id}</span>
                      <div className="buttons mb-0">

                        <button className={`button is-small mb-0 ${recipe.status_id === 'ACTIVE' ? 'is-outlined is-danger' : 'is-success'}`} type="button" style={{ height: "2.2em" }}
                          onClick={() => {
                            setshowConfirmDialog(true)
                            setconfDialogRecipe({
                              msg: `Are you sure you want to ${recipe.status_id === 'ACTIVE' ? 'Disable' : 'Enable'} the record?`,
                              show: 'status'
                            })
                          }} >{recipe.status_id === 'ACTIVE' ? "Disable" : "Enable"}</button>

                        {/* {recipe.status_id === 'ACTIVE' && <button className="button is-outlined is-small mb-0 is-info" style={{ height: "2.2em" }}>
                            <span className="icon is-tiny"> <i className="mdi mdi-square-edit-outline line-height-normal"></i> </span>

                            <span onClick={() => { navigate("/target/create-targets/" + recipe.id) }}>Edit</span>
                          </button>} */}

                      </div>
                    </div>
                  </div>
                  <div className="message-body p-0">
                    <table className="table is-striped is-hoverable is-fullwidth" style={{ border: "1px solid rgb(236, 236, 236)" }}>
                      <tbody>
                        <tr>
                          <td style={{ width: "30%" }}>Target Name:</td>
                          <td><b>{recipe.target_name}</b></td>
                        </tr>
                        <tr>
                          <td style={{ width: "30%" }}>Target Type:</td>
                          <td><b>{recipe.target_type_checked}</b></td>
                        </tr>
                        <tr>
                          <td>Created by</td>
                          <td><b>{recipe.created_by_name}</b></td>
                        </tr>
                        <tr>
                          <td>Created On</td>
                          <td><b>{recipe.created_at}</b></td>
                        </tr>
                        {recipe.updated_by_name && <tr>
                          <td>Last Updated by</td>
                          <td><b>{recipe.updated_by_name}</b></td>
                        </tr>}
                        {recipe.updated_by_name && <tr>
                          <td>Last Updated on</td>
                          <td><b>{recipe.updated_at}</b></td>
                        </tr>}
                      </tbody>
                    </table>
                  </div>
                </article>
              </div>
            </div>

            {recipe.target_type_checked === 'FTP' && <article className="message is-light">
              <div className="message-header">FTP</div>
              <div className="p-2">
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">IP Address</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          type="text"
                          disabled={id !== '0'}
                          placeholder="Enter FTP IP Address"
                          className="input" value={recipe.ftp_ip_addres || ''}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Port</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input type="text" disabled={id !== '0'} placeholder="Enter FTP Port" className="input"
                          value={recipe.ftp_port || ''}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">UserName</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input type="text" disabled={id !== '0'} placeholder="Enter FTP UserName" className="input"
                          value={recipe.ftp_username || ''}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Password</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input type="password" disabled={id !== '0'} placeholder="Enter FTP Password" className="input"
                          value={recipe.ftp_password || ''}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>}

            {recipe.target_type_checked === 'SCP' && <article className="message is-light">
              <div className="message-header">SCP</div>
              <div className="p-2">

                <div className="field is-horizontal pt-1 pb-1">
                  <div className="field-label">
                    <label className="label">&nbsp;</label>
                  </div>
                  <div className="field-body">
                    <div className="field is-narrow">
                      <label className="checkbox">
                        <span className="custom-radio">
                          <input type="radio" disabled={id !== '0'} name="scp" value="Key Based" checked={recipe.scp_based_type === 'Key Based'}
                          /><span className="radiomark"></span>
                        </span> Key Based
                      </label>
                    </div>

                    <div className="field is-narrow">
                      <label className="checkbox">
                        <span className="custom-radio">
                          <input type="radio" disabled={id !== '0'} name="scp" value="Password Based" checked={recipe.scp_based_type === 'Password Based'}
                          /><span className="radiomark"></span>
                        </span> Password Based
                      </label>
                    </div>
                  </div>
                </div>

                {(recipe.scp_based_type === 'Key Based' || recipe.scp_based_type === 'Password Based') &&
                  <div>
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">&nbsp;</label>
                      </div>
                      <div className="field-body">
                        <div className="field w-half">
                          <div className="is-normal">
                            <label className="label">Host Name / IP Address</label>
                          </div>
                          <div className="control">
                            <input type="text" disabled={id !== '0'} placeholder="Enter SCP Host Name / IP Address" className="input"
                              value={recipe.scp_host_name || ''}
                            />

                          </div>
                        </div>

                        <div className="field  w-half">
                          <div className="is-normal">
                            <label className="label">Port</label>
                          </div>
                          <div className="control">
                            <input type="text" disabled={id !== '0'} placeholder="Enter SCP Port" className="input"
                              value={recipe.scp_port || ''}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">&nbsp;</label>
                      </div>
                      <div className="field-body">
                        <div className="field w-half">
                          <div className="is-normal">
                            <label className="label">User Name</label>
                          </div>
                          <div className="control">
                            <input type="text" disabled={id !== '0'} placeholder="Enter SCP User Name" className="input"
                              value={recipe.scp_user_name || ''}
                            />

                          </div>
                        </div>

                        <div className="field  w-half">
                          <div className="is-normal">
                            <label className="label">Password</label>
                          </div>
                          <div className="control">
                            <input type="password" disabled={id !== '0'} placeholder="Enter SCP Password" className="input"
                              value={recipe.scp_password || ''}
                            />

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }



                {recipe.scp_based_type === 'Key Based' &&
                  <div className="field is-horizontal mt-2">
                    <div className="field-label is-normal">
                      <label className="label">&nbsp;</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <textarea type="text" disabled={id !== '0'} placeholder="Enter SCP key value" className="textarea"
                            value={recipe.scp_key_value || ''}
                          />

                        </div>
                      </div>
                    </div>
                  </div>
                }

              </div>
            </article>}

            {recipe.target_type_checked === 'SFTP' && <article className="message is-light">
              <div className="message-header">SFTP</div>
              <div className="p-2">

                <div className="field is-horizontal pt-1 pb-1">
                  <div className="field-label">
                    <label className="label">&nbsp;</label>
                  </div>
                  <div className="field-body">
                    <div className="field is-narrow">
                      <label className="checkbox">
                        <span className="custom-radio">
                          <input type="radio" disabled={id !== '0'} name="sftp" value="Key Based" checked={recipe.sftp_based_type === 'Key Based'}
                          /><span className="radiomark"></span>
                        </span> Key Based
                      </label>
                    </div>

                    <div className="field is-narrow">
                      <label className="checkbox">
                        <span className="custom-radio">
                          <input type="radio" disabled={id !== '0'} name="sftp" value="Password Based" checked={recipe.sftp_based_type === 'Password Based'}
                          /><span className="radiomark"></span>
                        </span> Password Based
                      </label>
                    </div>
                  </div>
                </div>

                {(recipe.sftp_based_type === 'Key Based' || recipe.sftp_based_type === 'Password Based') &&
                  <div>
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">&nbsp;</label>
                      </div>
                      <div className="field-body">
                        <div className="field w-half">
                          <div className="is-normal">
                            <label className="label">Host Name / IP Address</label>
                          </div>
                          <div className="control">
                            <input type="text" disabled={id !== '0'} placeholder="Enter SFTP Host Name / IP Address" className="input"
                              value={recipe.sftp_host_name || ''}
                            />

                          </div>
                        </div>

                        <div className="field  w-half">
                          <div className="is-normal">
                            <label className="label">Port</label>
                          </div>
                          <div className="control">
                            <input type="text" disabled={id !== '0'} placeholder="Enter SFTP Port" className="input"
                              value={recipe.sftp_port || ''}
                            />

                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">&nbsp;</label>
                      </div>
                      <div className="field-body">
                        <div className="field w-half">
                          <div className="is-normal">
                            <label className="label">User Name</label>
                          </div>
                          <div className="control">
                            <input type="text" disabled={id !== '0'} placeholder="Enter SFTP User Name" className="input"
                              value={recipe.sftp_user_name || ''}
                            />

                          </div>
                        </div>

                        <div className="field  w-half">
                          <div className="is-normal">
                            <label className="label">Password</label>
                          </div>
                          <div className="control">
                            <input type="password" disabled={id !== '0'} placeholder="Enter SFTP Password" className="input"
                              value={recipe.sftp_password || ''}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }

                {recipe.sftp_based_type === 'Key Based' &&
                  <div className="field is-horizontal mt-2">
                    <div className="field-label is-normal">
                      <label className="label">&nbsp;</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <textarea type="text" disabled={id !== '0'} placeholder="Enter SFTP key value" className="textarea"
                            value={recipe.sftp_key_value || ''}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </article>}

            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field is-grouped is-grouped-right">
                  <p className="control">
                    <button type="button" className="button is-link btn-standard" onClick={() => { navigate(-1) }}>Back</button>
                  </p>
                </div>
              </div>
            </div>

            {showConfirmDialog && <ConfirmDialog {...{ ...confDialogRecipe }}
                hide={() => setshowConfirmDialog(false)}
                executeCallback={() => removeUser()}
              />}
              
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="container">
        <Tabs style={{ width: "100%" }}>
          <TabList>
            <Tab><div className="tab-title">Targets</div></Tab>
            <Tab><div className="tab-title">History</div></Tab>
          </TabList>

          <TabPanel>{renderDetail()}</TabPanel>
          <TabPanel><HistoryGrid {...{ menu_id: "Target", reference_id: recipe.id }} /></TabPanel>
        </Tabs>

      </div>
    </>
  )
}

export default ViewTargets;
