import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as service from "../../services";
import { startLoading, stopLoading } from "../../components/Util";
import { useNavigate } from "react-router-dom";

function ViewUserHistory(props) {

    const { id } = useParams();
    const navigate = useNavigate();
    const [loading] = useState(true);
    const [recipe, setRecipe] = useState({ id });


    useEffect(() => {
        startLoading();
        let p1 = new Promise((resolve, reject) => {
            if (id !== '0') {
                service.get_history_data({ id }, res => {
                    if (res.data) {
                        console.log("res.data[0]", res.data[0]);
                        setRecipe({ ...res.data[0] });
                        resolve("");
                    }
                });
            } else resolve("");
        });

        Promise.all([p1])
            .then(values => {
                stopLoading();
            })
            .catch(error => {
                console.log(error.message);
            });

    }, [id]);

    useEffect(() => {
        if (!loading) {
            console.log("loaded...");
        }
    }, [loading]);

    const getValue = (oldValue, newValue) => {
        return <div style={{ color: oldValue === newValue ? 'green' : oldValue && !newValue ? 'red' : 'orange' }}>{newValue}</div>
    }
    return (
        <>
            <div className="container">
                <div className="column p-0 is-flex-grow-0">
                    <div className="field is-grouped  is-grouped-right is-flex-wrap-nowrap">
                        <div className="control mb-0 is-flex-shrink-0">
                            <div className="tags has-addons is-flex-wrap-nowrap">
                                <span className="tag has-background-success" style={{ height: "1.2rem", borderRadius: "2px" }}>&nbsp;</span>
                                <span className="tag" style={{ background: "none" }}>No Change</span>
                            </div>
                        </div>
                        <div className="control mb-0 is-flex-shrink-0">
                            <div className="tags has-addons is-flex-wrap-nowrap">
                                <span className="tag has-background-warning" style={{ height: "1.2rem", borderRadius: "2px" }}>&nbsp;</span>
                                <span className="tag" style={{ background: "none" }}>Add</span>
                            </div>
                        </div>
                        <div className="control mb-0 is-flex-shrink-0">
                            <div className="tags has-addons is-flex-wrap-nowrap">
                                <span className="tag has-background-danger" style={{ height: "1.2rem", borderRadius: "2px" }}>&nbsp;</span>
                                <span className="tag" style={{ background: "none" }}>Remove</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="columns">
                    <div className="column mt-4">
                        <div className="columns">
                            <div className="column is-half">
                                <article className="message is-light">
                                    <div className="message-header">Users History
                                        <div>
                                            {recipe.action_type === "CREATE" && <span className="tag is-success">{recipe.action_type}</span>}
                                            {recipe.action_type !== "CREATE" && <span className="tag is-warning">{recipe.action_type}</span>}
                                        </div>
                                    </div>
                                    <div className="message-body p-0">
                                        <table className="table is-striped is-hoverable is-fullwidth" style={{ border: "1px solid rgb(236, 236, 236)" }}>
                                            {recipe.after_json &&
                                                <tbody>
                                                    <tr>
                                                        <td><b>Field Name</b></td>
                                                        <td><b>Revison {" "}{recipe.version_id === 0 ? recipe.version_id : recipe.version_id - 1}</b></td>
                                                        {recipe.version_id > 0 && <td><b>Revision {" "}{recipe.version_id}</b></td>}
                                                    </tr>

                                                    <tr>
                                                        <td>First Name:</td>
                                                        <td><b>{recipe.action_type === "CREATE" ? recipe.after_json.first_name : recipe.before_json.first_name}</b></td>
                                                        {recipe.version_id === 0 ? <td></td> : <td><b>{recipe.action_type === "CREATE" ? recipe.after_json.first_name : getValue(recipe.before_json.first_name, recipe.after_json.first_name)}</b></td>}
                                                    </tr>

                                                    <tr>
                                                        <td>Last Name:</td>
                                                        <td><b>{recipe.action_type === "CREATE" ? recipe.after_json.last_name : recipe.before_json.last_name}</b></td>
                                                        {recipe.version_id === 0 ? <td></td> : <td><b>{recipe.action_type === "CREATE" ? recipe.after_json.last_name : getValue(recipe.before_json.last_name, recipe.after_json.last_name)}</b></td>}
                                                    </tr>

                                                    <tr>
                                                        <td>Email Id:</td>
                                                        <td><b>{recipe.action_type === "CREATE" ? recipe.after_json.email : recipe.before_json.email}</b></td>
                                                        {recipe.version_id === 0 ? <td></td> : <td><b>{recipe.action_type === "CREATE" ? recipe.after_json.email : getValue(recipe.before_json.email, recipe.after_json.email)}</b></td>}
                                                    </tr>

                                                    <tr>
                                                        <td>Phone Number:</td>
                                                        <td><b>{recipe.action_type === "CREATE" ? recipe.after_json.phone_number : recipe.before_json.phone_number}</b></td>
                                                        {recipe.version_id === 0 ? <td></td> : <td><b>{recipe.action_type === "CREATE" ? recipe.after_json.phone_number : getValue(recipe.before_json.phone_number, recipe.after_json.phone_number)}</b></td>}
                                                    </tr>

                                                    <tr>
                                                        <td>{recipe.action_type === "CREATE" ? 'Created By:' : 'Updated By:'}</td>
                                                        <td><b>{recipe.action_by_name}</b></td>
                                                        <td></td>
                                                    </tr>

                                                    <tr>
                                                        <td>{recipe.action_type === "CREATE" ?'Created On:':'Updated On:'}</td>
                                                        <td><b>{recipe.action_on}</b></td>
                                                        <td></td>
                                                    </tr>

                                                </tbody>}
                                        </table>
                                    </div>
                                </article>
                            </div>
                            <div className="column is-half">
                                <article className="message is-light">
                                    <div className="message-header">
                                        Revision
                                    </div>
                                    <div className="column">
                                        <div className="columns">
                                            <div className="column">
                                                <div className="content">
                                                    <div className="level-item has-text-centered">
                                                        <div>
                                                            <p className="title mt-2" style={{ color: "green" }}>{recipe.version_id}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>

                        <div className="field is-horizontal">
                            <div className="field-body">
                                <div className="field is-grouped is-grouped-right">
                                    <p className="control">
                                        <button type="button" className="button is-link btn-standard" onClick={() => { navigate(-1) }}>Back</button>
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewUserHistory;
