import React from 'react'
import { useNavigate } from 'react-router-dom'

function ConfirmDialog(props) {
    //console.log("props", props);
    const navigate = useNavigate()
    const msg = props.msg
    const executeChildMethod = props.executeChildMethod
    const show = props.show

    return (
        <div className="popup">
            <div className="modal" style={{ display: "flex" }}>
                <div className="modal-card" >
                    <header className="modal-card-head">
                        <p className="modal-card-title">Confirmation Message</p>
                    </header>
                    <section className="modal-card-body">
                        <div className="columns" style={{ display: "flex", alignItems: "center" }}>
                            <div className="column" dangerouslySetInnerHTML={{ __html: msg }}></div>
                        </div>
                    </section>
                    <footer className="modal-card-foot is-justify-content-right">
                        {show ?
                            <div className="field is-horizontal">
                                <div className="field-body">
                                    <div className="field is-grouped is-grouped-right">
                                        <p className="control">
                                            <button className="button is-link btn-standard" type="button"
                                                onClick={() => props.hide()}>No</button>
                                        </p>
                                        <p className="control">
                                            <button className="button is-primary btn-standard" type="button"
                                                onClick={() => props.executeCallback()}>Yes</button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            :
                            <button className="button is-primary btn-standard" type="button" style={{ minWidth: '90px' }}
                                onClick={() => {
                                    if (props.goback) navigate(-1)
                                    if (props.is_link) navigate(props.path)
                                    if (executeChildMethod) props.cb();
                                }}>OK</button>}
                    </footer>
                </div>
            </div>
        </div>
    )
}
export default ConfirmDialog
