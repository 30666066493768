import { get, loginpost, post } from "./service";
//import { establishUserType } from "./../Components/Util";

export const login = (payload, onDone) => {
    payload["from"]="server";
    loginpost("/api/security/authenticate", payload, (res) => {
        onDone(res);
    });
};

export const introspect = (onDone) => {
    get("/api/security/introspect", (res) => {
        //if (res.user && res.auths) res.userType = establishUserType(res.auths, res.user);
        onDone(res);
    });
};

export const authorizations = (onDone) => {
    get("/api/security/authorizations", (res) => {
        //if (res.user && res.auths) res.userType = establishUserType(res.auths, res.user);
        onDone(res);
    });
};

export const reset_password = (payload, onDone) => {
    post("/api/security/reset_password", payload, (res) => {
        onDone(res);
    });
};

export const forgot_password = (payload, onDone) => {
    post("/api/security/forgot_password", payload, (res) => {
        onDone(res);
    });
};

export const logout = (onDone) => {
    get("/api/security/logout", res => {
        onDone(res);
    });
};

export const sessionTimeOut = (onDone) => {
    get("/api/security/session-time-out", res => {
        onDone(res);
    });
};