import React, { useState, useEffect } from 'react'
import * as service from "../../services";
import { getdate, startLoading, stopLoading } from "../../components/Util";
import { useNavigate, useLocation } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import HistoryGrid from '../History/HistoryGrid';

function ViewSchedule(props) {

    const navigate = useNavigate()
    const { state } = useLocation();
    const [loading, setLoading] = useState(true);
    const [scheduleArr, setScheduleArr] = useState([])

    useEffect(() => {
        startLoading();

        let p1 = new Promise((resolve, reject) => {
            service.get_schedulardetails({ id: state.path_id }, res => {
                if (res.data) {
                    let data = res.data
                    console.log("data", data);
                    data = data.map(item => {
                        return {
                            ...item
                        }
                    });
                    setScheduleArr([...data]);
                    resolve("");
                } else resolve("");

            });
        });

        Promise.all([p1])
            .then(values => {
                setLoading(false)
                stopLoading();
            })
            .catch(error => {
                console.log(error.message);
            });

    }, []);

    useEffect(() => {
        if (!loading) {
            //console.log("loaded...");
        }
    }, [loading]);

    const renderDetail = () => {
        return (
            <>
                <div className="container">

                    <div className='mt-2'>
                        {scheduleArr && scheduleArr.length > 0 ? scheduleArr.map((item, index) => {
                            return (<>
                                <article key={index} className="message is-light">
                                    <div className="message-header">Schedule ({index + 1})
                                        <div className="mx-1 tag is-light is-success">{item.status_id}</div>
                                    </div>
                                    <div className="message-body p-0">
                                        <table className="table is-striped is-hoverable is-fullwidth">
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "30%" }}>Schedule Name</td>
                                                    <td><b>{item.schedules_name}</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%" }}>Backup Type</td>
                                                    <td><b>{item.backup_type}</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%" }}>Backup Frequency Type</td>
                                                    <td><b>{item.backup_frequency_type || item.backup_frequency.label}</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%" }}>Backup Frequency Value</td>
                                                    <td><b>{item.backup_frequency_value}</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%" }}>Schedule Start Date</td>
                                                    <td><b>{item.scheduled_start_date}</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%" }}>Schedule Start Time</td>
                                                    <td><b>{item.scheduled_time_value}</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%" }}>Retain Days</td>
                                                    <td><b>{item.retain_days}</b></td>
                                                </tr>

                                                <tr>
                                                    <td style={{ width: "30%" }}>Target</td>
                                                    <td><b>{item.target_data.map((item, index) => {
                                                        return <div key={index}>{item.label}</div>
                                                    })}</b></td>
                                                </tr>                                                

                                                {item.updated_by_name && <tr>
                                                    <td>Added by</td>
                                                    <td><b>{item.updated_by_name}</b></td>
                                                </tr>}
                                                {item.updated_by_name && <tr>
                                                    <td>Added on</td>
                                                    <td><b>{item.updated_at}</b></td>
                                                </tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </article>
                            </>)
                        }) :
                            <div className="blank-div">No Data Found.</div>}
                    </div>

                    <div className="field is-horizontal">
                        <div className="field-body mt-5 mb-5">
                            <div className="field is-grouped is-grouped-right">
                                <p className="control">
                                    <button className="button is-primary btn-standard" type="button"
                                        onClick={() => navigate(-1)}>Back</button>
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    }

    return (
        <>
            <div className="container">
                <Tabs style={{ width: "100%" }}
                //</div>defaultIndex={parseInt(localStorage.getItem('tab_index') || 0)} onSelect={index => localStorage.setItem('tab_index', index)}
                >
                    <TabList>
                        <Tab><div className="tab-title">Schedules</div></Tab>
                        <Tab><div className="tab-title">History</div></Tab>
                    </TabList>

                    <TabPanel>{renderDetail()}</TabPanel>
                    <TabPanel><HistoryGrid {...{ menu_id: "Schedules", reference_id: state.path_id }} /></TabPanel>
                </Tabs>

            </div>

        </>
    )
}
export default ViewSchedule
