import React, { useEffect, useState } from "react";
import * as service from "../../services";
import { startLoading, stopLoading } from "../../components/Util";
import Select from 'react-select';
import moment from "moment";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";

const UsersGrid = (props) => {
  const navigate = useNavigate();
  const initialstate = { sort_by: { value: 'created_at', label: "Created On" }, record_count: 20, sort_direction: 'desc', is_deleted: 'false' }
  const [loading, setLoading] = useState(true)
  const [store, setStore] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [payload, setPayload] = useState(initialstate);
  //const [deleteModal, setDeleteModal] = useState(false);
  //const [deleteData, setDeleteData] = useState();

  const sort_direction_options = [
    { value: 'name', label: "Name" },
    { value: 'email', label: "Email" },
    { value: 'created_at', label: "Created On" },
    { value: 'created_by_name', label: "Created By" },
    { value: 'status_name', label: "Status" }
  ];

  const handleChange = (prop, value) => {
    const _payload = JSON.parse(JSON.stringify(payload));
    _payload[prop] = value;
    setPayload(_payload);
  };

  const handleLoadMore = () => {
    setShowMore(false);
    let obj = store[store.length - 1];
    sessionStorage.setItem('user_filter', JSON.stringify(payload));
    const _payload = JSON.parse(JSON.stringify(payload));
    _payload.sort_by = _payload.sort_by.value;
    _payload.last_key = obj[_payload.sort_by + '_srt'];
    service.get_users(_payload, res => {
      setStore([...store, ...res.data]);
      if (res.data.length > 19) setShowMore(true)
    });
  };

  const sortClickHandler = val => {
    setPayload({ ...payload, sort_by: val, last_key: null });
  }

  const sort_directionClickHandler = () => {
    setPayload({ ...payload, sort_direction: payload.sort_direction === "asc" ? "desc" : "asc", last_key: null });
  }
  const isDeleted = () => {
    setPayload({ ...payload, is_deleted: payload.is_deleted === "false" ? "true" : "false", last_key: null });
  }

  const handleReset = () => {
    setPayload(initialstate);
  }

  useEffect(() => {
    startLoading();
    sessionStorage.setItem('user_filter', JSON.stringify(payload));
    const _payload = JSON.parse(JSON.stringify(payload));
    if (_payload.created_from_date && _payload.created_to_date) {
      _payload.created_from_date = moment(payload.created_from_date).format("DD-MMM-yyyy") + ' 00:00:01';
      _payload.created_to_date = moment(payload.created_to_date).format("DD-MMM-yyyy") + ' 23:59:59';
    }
    _payload.sort_by = _payload.sort_by.value;
    service.get_users(_payload, res => {
      setStore(res.data);
      if (res.data.length > 19) setShowMore(true);
      setLoading(false)
    });
  }, [payload]);

  // const removeUser = (data) => {
  //   const _payload = JSON.parse(JSON.stringify(payload));
  //   _payload.sort_by = _payload.sort_by.value;
  //   startLoading();
  //   service.upsert_user({ id: data.id, status_id: "DISABLED", operation_type: "DELETE", ...data }, res => {
  //     service.get_users(_payload, res => {
  //       if (res.data) {
  //         setStore(res.data);
  //         stopLoading();
  //         setDeleteModal(false);
  //       }
  //     });
  //   })
  // }

  // const handleShowDeleteUserModel = (data) => {
  //   setDeleteModal(true);
  //   setDeleteData(data);
  // };

  // const handleHideDeleteModal = () => {
  //   setDeleteModal(false);
  // };

  const renderGrid = () => {
    return <>
      {store && store.length > 0 ?
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="table-container">
                <table className="table is-striped  is-hoverable is-fullwidth">
                  <thead>
                    <tr className="extra-spacing-tr"></tr>
                    <tr>
                      <th scope="col" width="30%"><span className="text-muted">Name</span></th>
                      <th scope="col"><span className="text-muted">Email</span></th>
                      <th scope="col" width="15%"><span className="text-muted">Created By</span></th>
                      <th scope="col" width="15%"><span className="text-muted">Updated By</span></th>
                      <th scope="col" width="10%"><span className="text-muted">Status</span></th>
                    </tr>
                  </thead>
                  <tbody>
                    {store.map((data, key) => {
                      const created_at = moment(new Date(data.created_at_ms));
                      let created_at_dt = created_at.format('DD-MMM-YYYY hh:mm A');
                      const updated_at = moment(new Date(data.updated_at_ms));
                      let updated_at_dt = updated_at.format('DD-MMM-YYYY hh:mm A');
                      return (
                        <tr key={data.id}>
                          <td>
                            <div className="txt-primary">
                              <h6 className="clickable text-darkprime" onClick={() => { navigate("/user/userdetail/" + data.id) }}><b>{data.first_name + ' ' + data.last_name}</b>
                              </h6>
                            </div>
                          </td>
                          <td>{data.email}</td>
                          <td>
                            {data.created_by_name}
                            <div style={{ fontSize: "0.75em", color: "#808080" }}>
                              <span>{created_at_dt} </span>
                            </div>
                          </td>
                          <td>
                            {data.updated_by_name}
                            {data.updated_by_name && <div style={{ fontSize: "0.75em", color: "#808080" }}>
                              <span>{updated_at_dt} </span>
                            </div>}
                          </td>
                          <td style={{color:data.status_id==='ACTIVE'?"green":'red'}} >{data.status_id}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="blank-div">No Data Found.</div>
      }
      <div style={{ "textAlign": "center" }}> {store && store.length > 19 && showMore ? <button className="btn btn-outline-primary" onClick={() => handleLoadMore()} id="loadMore" > Show More ... </button> : undefined}</div>
    </>
  }

  loading ? startLoading() : stopLoading();
  return (
    !loading && <>
      <div className="container">
        <br />
        <div className="columns is-mobile">
          <div className="column">
            <h1 className="title">{payload.is_deleted === "false" ? "Users" : "Archived Users"}</h1>
          </div>
          <div className="column is-flex-grow-0">
            <div className="buttons has-addons is-right">
              <button className="button is-primary"
                onClick={() => { navigate("/user/edit/0") }}>Create New User</button>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="columns is-desktop" style={{ paddingTop: "10px", marginBottom: "0px" }}>
              <div className="column is-half-desktop">
                <label htmlFor="Search" className="form-label mb-0"><small>SEARCH</small></label>
                <div className="control has-icons-left">
                  <input placeholder='Search' type="search" className="input" maxLength="100"
                    value={payload.search_string || ''}
                    onChange={(e) => { handleChange('search_string', e.target.value) }} />
                  <span className="icon is-small is-left"><i className="mdi mdi-magnify"></i></span>
                </div>
              </div>
              <div className="column">
                <div className="columns is-mobile is-vcentered">
                  <div className="column">
                    <label className="form-label mb-0"><small>SORT BY</small></label>
                    <div className="control">
                      <Select
                        value={payload.sort_by || sort_direction_options[0]}
                        options={sort_direction_options || []}
                        required
                        placeholder="Sort By"
                        onChange={e => {
                          sortClickHandler(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="column is-flex-grow-0">
                    <label className="form-label mb-0">&nbsp;</label>
                    <button className="button" style={{ minWidth: "90px" }} onClick={sort_directionClickHandler}>
                      <span className="icon is-small">
                        <i className={payload.sort_direction === 'asc' ? "mdi mdi-sort-reverse-variant" : "mdi mdi-sort-variant"}></i>
                      </span>
                      <span>{payload.sort_direction === 'asc' ? "A-Z" : "Z-A"}</span>
                    </button>
                  </div>
                  <div className="column is-flex-grow-0">
                    <label className="form-label mb-0">&nbsp;</label>
                    <button type="button" className="button is-link" onClick={() => handleReset()}>Reset</button>
                  </div>
                  {/* <div className="column is-clickable is-flex-grow-0">
                    <label className="form-label mb-0">&nbsp;</label>
                    <i className={payload.filter ? 'mdi mdi-chevron-double-up' : 'mdi mdi-chevron-double-down'} onClick={(e) => {
                      handleChange('filter', !payload.filter)
                    }} style={{ verticalAlign: "middle" }}></i>
                  </div> */}
                </div>
              </div>
            </div>
            {payload.filter &&
              <div className="columns is-tablet sub-filter">
                <div className="column is-half-tablet is-one-quarter-desktop">
                  <div className="column">
                    <label className="label">From Date</label>
                    <div className="field is-horizontal">
                      <div className="field-body">
                        <div className="field">
                          <div className="control is-expanded has-icons-left">
                            <DatePicker
                              placeholderText="From Date"
                              selected={payload.created_from_date ? new Date(payload.created_from_date) : null}
                              className="input form-control"
                              onChange={(date) => handleChange("created_from_date", date)}
                              dateFormat="dd-MMM-yyyy"
                              showMonthDropdown={true}
                              selectsStart
                              startDate={payload.created_from_date ? new Date(payload.created_from_date) : null}
                              endDate={payload.created_to_date ? new Date(payload.created_to_date) : null}
                              maxDate={payload.created_to_date ? new Date(payload.created_to_date) : null}
                            />
                            <span className="icon is-small is-left">
                              <i className="mdi mdi-calendar-month-outline"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-half-tablet is-one-quarter-desktop">
                  <div className="column">
                    <label className="label">To Date</label>
                    <div className="field is-horizontal">
                      <div className="field-body">
                        <div className="field">
                          <div className="control is-expanded has-icons-left">
                            <DatePicker
                              placeholderText="To Date"
                              selected={payload.created_to_date ? new Date(payload.created_to_date) : null}
                              className="input form-control "
                              onChange={(date) => handleChange("created_to_date", date)}
                              dateFormat="dd-MMM-yyyy"
                              showMonthDropdown={true}
                              selectsEnd
                              startDate={payload.created_from_date ? new Date(payload.created_from_date) : null}
                              endDate={payload.created_to_date ? new Date(payload.created_to_date) : null}
                              minDate={payload.created_from_date ? new Date(payload.created_from_date) : null}
                            />
                            <span className="icon is-small is-left">
                              <i className="mdi mdi-calendar-month-outline"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-half-tablet is-one-quarter-desktop">
                  <div className="column">
                    <label className="label">Status</label>
                    <div className="field is-horizontal">
                      <div className="field-body">
                        <div className="field">
                          <div className="control is-expanded has-icons-left">
                            <button className="button" onClick={isDeleted}>
                              <span>{payload.is_deleted === "false" ? "Active" : "Archived"}</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
          </div>
        </div>

        <div className="row px-0">
          {renderGrid()}
        </div>
        {/* {deleteModal ? <div className="modal-backdrop fade show"></div> : ""}
        <div className={"modal fade " + (deleteModal ? "show d-block" : "d-none")}
          id="staticBackdrop"
          aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content p-3">
              <div className="modal-body">
                <div className="row">
                  <div className="col">
                    <h4 className="text-center">Are you sure you want to delete the record?</h4>
                  </div>
                </div>
                <div className="d-grid gap-2 d-md-flex justify-content-md-center mt-3">
                  <button className="btn btn-secondary" type="button"
                    onClick={handleHideDeleteModal}>
                    No
                  </button>
                  <button className="btn btn-primary text-white" type="button"
                    onClick={() => { removeUser(deleteData) }}>
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <br />
      </div>
    </>);
};
export default UsersGrid;
