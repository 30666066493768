import { post } from "./service";

export const get_machines = (payload, onDone) => {
    post("/api/machines/grid", payload, (res) => {
        onDone(res);
    });
};
export const get_machine_details = (payload, onDone) => {
    post("/api/machines/machinedetails", payload, (res) => {
        onDone(res);
    });
};
export const upsert_machine = (payload, onDone) => {
    post("/api/machines/upsert", payload, (res) => {
        onDone(res);
    });
};
export const upsert_schedular = (payload, onDone) => {
    post("/api/machines/upsertschedular", payload, (res) => {
        onDone(res);
    });
};
export const get_locations = (payload, onDone) => {
    post("/api/machines/locationslist", payload, (res) => {
        onDone(res);
    });
};
export const set_locations = (payload, onDone) => {
    post("/api/machines/setlocations", payload, (res) => {
        onDone(res);
    });
};
export const get_schedulardetails = (payload, onDone) => {
    post("/api/machines/schedulardetails", payload, (res) => {
        onDone(res);
    });
};
export const update_location_status = (payload, onDone) => {
    post("/api/machines/update/location", payload, (res) => {
        onDone(res);
    });
};