import React from "react";
import lims_eln_logo from "../../src/assets/images/LabIconics-ADBS-w.png";
import { useAuth } from "../context/auth";

function Header(props) {
  let { logout, user } = useAuth();

  return (
    <>
      <nav className="navbar is-blue lims-eln-nav is-fixed-top" style={{ height: "4rem" }} aria-label="primary navigation">
        <div className="navbar-brand">
          <a className="navbar-item" href="/">
            <img src={lims_eln_logo} alt="Lab Iconics LIMS" height="35"/>
          </a>
        </div>
        
        <div className={"navbar-menu"}>
          <div className="navbar-end">
            <div className="navbar-item">
                <div className="site-type">
                  <div>{user && <span className="nav-link">
                    {user.first_name} {user.last_name}
                  </span>}</div>
                </div>
              </div>
              <div className="navbar-item">
                <span title="Logout" className="icon is-clickable" onClick={() => { logout() }}>
                  <i className="mdi mdi-logout" ></i>
                </span>
              </div>
            </div>
          </div>  
        
      </nav>
    </>
  );
}

export default Header;
