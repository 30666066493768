import React, { useState, useEffect } from 'react'
import { stopLoading } from './Util';
import { useNavigate } from "react-router-dom";

function TokenValidator() {

    const navigate = useNavigate();
    const [isTokenExpired, setisTokenExpired] = useState(false)
    const [chekedToken, setchekedToken] = useState('')

    useEffect(() => {
        clearInterval(chekedToken)
        let intervaldata = setInterval(() => {
            const isTokenExpired = sessionStorage.getItem('isTokenExpired') || false;
            if (isTokenExpired) {
                setisTokenExpired(isTokenExpired)
                clearInterval(intervaldata)
            }
        }, 1000);
        setchekedToken(chekedToken)
    }, [chekedToken])

    isTokenExpired && stopLoading();
    return (
        isTokenExpired && <div className="popup">
            <div className="modal" style={{ display: "flex" }}>
                <div className="modal-card" style={{ width: '400px' }}>
                    <header className="modal-card-head">
                        <p className="modal-card-title"><i className="mdi mdi-account-lock" ></i>Session Terminated</p>
                    </header>
                    <section className="modal-card-body">
                        Your session has been terminated due to another Active Session.
                    </section>
                    <footer className="modal-card-foot is-justify-content-right">
                        <button className="button is-primary btn-standard" style={{ minWidth: "100px" }} type="button" onClick={e => {
                            setisTokenExpired(false)
                            sessionStorage.removeItem('isTokenExpired');
                            navigate("/login");
                        }}>OK</button>
                    </footer>
                </div>
            </div>
        </div>
    )
}
export default TokenValidator
