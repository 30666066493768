import { post,download } from "./service";

export const get_backup_report = (payload, onDone) => {
    post("/api/reports/backup_report", payload, (res) => {
        onDone(res);
    });
};

export const get_download_report = (payload, onDone) => {
    post("/api/reports/download_report", payload, (res) => {
        onDone(res);
    });
};
export const get_scheduled_report = (payload, onDone) => {
    post("/api/reports/scheduled_report", payload, (res) => {
        onDone(res);
    });
};
export const get_session_log_report = (payload, onDone) => {
    post("/api/reports/session_log_report", payload, (res) => {
        onDone(res);
    });
}; 
export const downloadReport = (module, type, fileName, payload, onDone) => {
    download(encodeURI("/api/reports/download-report/" + module + "/" + type + "/" + fileName), payload, (res) => {
        serveFile(fileName, res);
        onDone();
    });
};

let serveFile = (fileName, res) => {
    const url = window.URL.createObjectURL(new Blob([res]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
};